import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Swiper from 'react-id-swiper';

class ServiceGridSlider extends Component{
    render(){
        const params = {
            slidesPerView : 3,
            loop: true,
            speed: 1000,
            watchSlidesVisibility: true,
            autoplay: {
                delay: 3000,
            },
            // Responsive breakpoints
            breakpoints: {
                1499:{
                    slidesPerView : 3
                },

                991:{
                    slidesPerView : 2
                },

                767:{
                    slidesPerView : 1

                },

                575:{
                    slidesPerView : 1
                }
            }

        }
        let data = [
            {img:'1. PCB Design.png', title: 'PCB Design', link:'pcbdesign'},
            {img:'2_PCB Fabrication.jpg', title: 'PCB Fabrication & Assistance', link:'Pcbfabrication'},
            {img:'3_PCB Assembly.jpg', title: 'PCB Assembly', link:'Pcbassembly'},
            {img:'4_Component Sourcing.jpg', title: 'Component Sourcing', link:'Componentsourcing'},
            {img:'5_Turnkey Product Developement.jpg', title: 'Turnkey Product Development', link:'Turnkeyproduct'},
            {img:'rever.jpeg', title: 'Reverse Engineering Services', link:'Reverseservice'},
            // {img:'7_Prototype Design.jpg', title: 'Prototype design', link:'service-details-left-sidebar'},
            {img:'induct.jpeg', title: 'Inductor Design Assistance', link:'Inductordesign'},
            {img:'9_Enclosure Design.jpg', title: 'Enclosure Design', link:'Enclosuredesign'}
            // {img:'9_Enclosure Design.jpg', title: 'Enclosure Design', postExcerpt: 'Our experienced mechanical team has experience... ', link:'service-details-left-sidebar'}
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
                    <div className="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
                        <div className="blog-post-slider__image section-space--bottom--30">
                        <Link to={`${process.env.PUBLIC_URL}/${val.link}`}><img src={`assets/img/blog/${val.img}`} className="img-fluid" alt="" /></Link>
                            {/* <a href={`${process.env.PUBLIC_URL}/${val.link}`}><img src={`assets/img/blog/${val.img}`} className="img-fluid" alt="" /></a> */}
                            </div>
                            <div className="blog-post-slider__content">
                            {/* <p className="post-date"> {val.date}</p> */}
                            <h3 className="post-title mt-30">
                            <Link to={`${process.env.PUBLIC_URL}/${val.link}`}>{val.title}</Link>
                                {/* <a href={`${process.env.PUBLIC_URL}/${val.link}`}>{val.title}</a> */}
                            </h3> 
                            {/* <p className="post-excerpt">{val.postExcerpt}<a href={`${process.env.PUBLIC_URL}/${val.link}`} className="see-more-link text-decoration-none">See More</a></p> */}
                            {/* <a href={`${process.env.PUBLIC_URL}/${val.link}`} className="see-more-link">SEE MORE</a> */}
                        </div>
                    </div>
                </div>
            )
        });
        return(
            <div>
                {/*====================  service grid slider area ====================*/}
                <div className="service-grid-slider-area section-space--inner--50">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                        <h2 className="section-title section-space--bottom--50">Our Services <span className="title-icon" /></h2>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="service-slider">
                            <Swiper {...params}>
                                {DataList}
                            </Swiper>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of service grid slider area  ====================*/}

            </div>
        )
    }
}


export default ServiceGridSlider;