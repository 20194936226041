import React, { Component } from "react";
import {Link} from 'react-router-dom';
import NavBar from "../components/NavBar";
import FeatureIcon from "../components/FeatureIcon";
import Funfact from "../components/Funfact";
import TeamMemberGrid from "../components/TeamMemberGrid";
import TestimonialSlider from "../components/TestimonialSlider";
import ComponentBrand from "../components/ComponentBrand";
import Pcbassemblyjob from "../components/Pcbassemblyjob";
import Pcbinsertion from "../components/Pcbinsertion";
import Pcbaddservice from "../components/Pcbaddservice";
import Pcbnote from "../components/Pcbnote";
import Calltoactionthree from '../components/Calltoactionthree';
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ModalVideo from "react-modal-video";
class Pcbassembly extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    let data = [
      {
        // pageLink: "project-details",
        img: "ass2.jpg",
        projectTitle: "SMT Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "ass3.jpg",
        projectTitle: "BGA Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      ,
      {
        // pageLink: "project-details",
        img: "ass6.jpg",
        projectTitle: "Rigid Flex PCB Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "ass5.jpeg",
        projectTitle: "Mixed Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      
      {
        // pageLink: "project-details",
        img: "ass4.jpeg",
        projectTitle: "Through-Hole Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                  <img
                    src={`assets/img/projects/${val.img}`}
                    className="img-fluid w-100"
                    alt=""
                  />
              </div>
            </div>
            <div className="service-grid-item__content p-3">
              <h3 className="title m-0">
                  {val.projectTitle}
              </h3>
              {/* <a
                href={`${process.env.PUBLIC_URL}/${val.pageLink}`}
                className="see-more-link"
              >
                SEE MORE
              </a> */}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>PCB Assembly</h1>
                  {/* <ul className="page-breadcrumb">
                    <li>
                    <Link to = {`${process.env.PUBLIC_URL}/home-two`}>Home</Link>
                    </li>
                    <li>PCB Assembly</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--0 pt-4">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-3">
            <div className="container">
              <div className="row row-25 align-items-center">
                
                
              <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>All PCB Specs Support Assembly</h3>
                    <p>
                    We are dealing with SMD and THT assembly services as partial and full turn-key with PCBA Testing, and continuous monitoring of quality till final completion.
                    </p>
                    {/* <a
                      href="services"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Learn More
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/about/assem.jpg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-12 col-12 mb-30">
                  <div className="about-content-two">
                    <p>
                    We offer complete PCB assembly services in India, capable of providing turn-key and partial turn-key printed circuit board assembly services. For full turn-key, we take care of the entire process, including manufacturing assistance of Printed Circuit Boards, procurement of components (100% original), PCBA Testing, continuous monitoring of quality, and final assembly. Whereas for partial turn-key, the customer can provide the PCBs and certain components, and the remaining parts will be handled by us.
                    </p>
                    <h2>
                    Assembly capabilities: 
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
                    {/*====================  project page content ====================*/}
        <div className="page-wrapper section-space--inner--0 ">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="project-item-wrapper">
                    <div className="row">{Datalist}</div>
                  </div>
                </div>
              </div>
              {/* <div className="row section-space--top--60">
                <div className="col">
                  <ul className="page-pagination">
                    <li>
                      <a href="/">
                        <i className="fa fa-angle-left" /> Prev
                      </a>
                    </li>
                    <li className="active">
                      <a href="/">01</a>
                    </li>
                    <li>
                      <a href="/">02</a>
                    </li>
                    <li>
                      <a href="/">03</a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-angle-right" /> Next
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/* Pcbassemblyjob */}
          <Pcbassemblyjob />


        {/*====================  End of project page content  ====================*/}
          <div className="about-section section-space--inner--bottom--0 pb-5">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-12 col-12 mb-30">
                  <div className="about-content-two">
                    <p>
                    We offer one-stop high-quality, low-mid-high volume yet affordable printed circuit board Assembly service in India. We also offer Propagate Service to satisfy more advanced and customized requirements, including Product design, PCB layout service, PCB stencil service and PCB manufacturing service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*====================  End of project page content  ====================*/}
          <div className="about-section section-space--inner--bottom--0">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-12 col-12 mb-30">
                  <div className="about-content-two">
                    <p>
                    To deliver true value and convenience to our PCB fabrication clients, we expand our business to components sourcing and turnkey PCB Assembly services ranging from prototype, low-to-middle volume to high volume up to 1 million pcs. We can mount components on circuit boards using surface mount technology (SMT), through-hole technology (THT), and manual insertion:
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Pcbassemblyjob */}
          <Pcbinsertion />

          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Full turnkey PCBs</h3>
                    <p>
                    We creates an unbroken thread from design to delivery. Receive a complete quote for fabrication, assembly, and components. Our white-glove service brings your product to life.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/projects/page-1.jpeg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
           {/*About section start*/}
           <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Partially consigned assembly</h3>
                    <p>
                    Some parts provided by the designer - we will source the rest. We will work with your supply chain to get your PCBs assembled and to market quickly.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/projects/page-2.jpeg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
           {/*About section start*/}
           <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Consigned assembly</h3>
                    <p>
                    All components are provided by the PCB designer. We will fully assemble your PCBs and ship them to you. Quick turn times available, with fully transparent pricing.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/projects/ass9.jpg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two">
                    <h3>One-Stop PCB Assembly Services, Capabilities, And Testing.</h3>
                    <p>
                    We source components from verified trusted distributors for one go success. We also warehouse a significant inventory of common parts. If we cannot source a specific component, our engineers will make recommendations for fair-price alternatives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/* Pcbaddservice */}
          <Pcbaddservice />

          {/* Pcbnote */}
          <Pcbnote />
          
          {/* Fun fact */}
          {/* <Funfact /> */}

          {/* Team member */}
          {/* <TeamMemberGrid /> */}

          {/* Testimonial Slider */}
          {/* <TestimonialSlider /> */}

          {/* Brand logo */}
          <ComponentBrand background="" />
        </div>

        {/* Service Tab */}
        <Calltoactionthree/>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Pcbassembly;
