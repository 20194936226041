import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class ServiceTabExample extends Component{

    render(){
        
        /* service tab menu */
        let serviceTabMenuData = [
            {iconName: 'flaticon-002-welding', tabMenuName: 'PCB DESIGN'},
            {iconName: 'flaticon-004-walkie-talkie', tabMenuName: 'PCB FABRICATION & ASSISTANCE'},
            {iconName: 'flaticon-015-cart', tabMenuName: 'PCB ASSEMBLY'},
            {iconName: 'flaticon-010-tank-1', tabMenuName: 'COMPONENT SOURCING'},
            {iconName: 'flaticon-015-cart', tabMenuName: 'TURNKEY PRODUCT DEVELOPMENT'},
            {iconName: 'flaticon-010-tank-1', tabMenuName: 'ENCLOSURE DESIGN'},
            {iconName: 'flaticon-010-tank-1', tabMenuName: 'REVERSE ENGINEERING SERVICES'}
        ];

        let serviceTabMenuDatalist = serviceTabMenuData.map((val, i)=>{
            return(
                <Tab key={i}>  <span className="icon"><i className={val.iconName} /></span> <span className="text">{val.tabMenuName}</span></Tab>
            )
        });

        
        /* service tab content */
        
        let serviceTabContentData = [
            {bgUrl: "1-pcb_design.jpg", contentTitle: 'PCB DESIGN', contentDesc: 'Our team consists of experienced engineers, no matter how complicated the project is, our engineers can design the schematic diagram to meet your requirements.', serviceLink: 'service-details-left-sidebar'},
            {bgUrl: "pcb_feb_2.jpeg", contentTitle: 'PCB FABRICATION & ASSISTANCE', contentDesc: 'We are dealing with manufacturing assistance for quick turn Prototypes & Bulk Quantity manufacturing for Single, Multilayer, Metal Clad PCBs.', serviceLink: 'service-details-left-sidebar'},
            {bgUrl: "pcb_ass3.jpeg", contentTitle: 'PCB ASSEMBLY', contentDesc: 'We are dealing with SMD and THT assembly services as partial and full turn-key with PCBA Testing, and continuous monitoring of quality till completion.', serviceLink: 'service-details-left-sidebar'},
            {bgUrl: "4_COMPONENT_SOURCING.jpg", contentTitle: 'COMPONENT SOURCING', contentDesc: 'We provide you with the best electronic component prices after analyzing various Trusted electronic component distributors ', serviceLink: 'service-details-left-sidebar'},
            {bgUrl: "5_.png", contentTitle: 'TURNKEY PRODUCT DEVELOPMENT', contentDesc: 'Our production unit is fully capable of manufacturing and delivering products in any number and volume, with short lead times.', serviceLink: 'service-details-left-sidebar'},
            {bgUrl: "6_ENCLOSURE_DESIGN.jpg", contentTitle: 'ENCLOSURE DESIGN', contentDesc: 'Our experienced mechanical team has experience in designing enclosures that have a great fit, finish, and function while staying within budget.', serviceLink: 'service-details-left-sidebar'},
            {bgUrl: "Custom_dimensions.jpeg", contentTitle: 'REVERSE ENGINEERING SERVICES', contentDesc: 'We have deep experience in PCB reverse Engineering for bulk production, which includes both reverses with/without dismantling the layers and components.', serviceLink: 'service-details-left-sidebar'}
        ];

        let serviceTabContentDatalist = serviceTabContentData.map((val, i)=>{
            return(
                <TabPanel key={i}>
                    <div className="service-tab__single-content-wrapper" style={{ backgroundImage: `url(assets/img/backgrounds/${val.bgUrl})` }}>
                        <div className="service-tab__single-content">
                            <h3 className="service-tab__title">{val.contentTitle}</h3>
                            <p className="service-tab__text">{val.contentDesc}</p>
                            {/* <a href={`${process.env.PUBLIC_URL}/${val.serviceLink}`} className="see-more-link">SEE MORE</a> */}
                        </div>
                    </div>
                </TabPanel>
            )
        });

        return(
            <div>

                {/*====================  service tab area ====================*/}
                <div className="service-tab-area section-space--bottom-- pt-0">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                        <h2 className="section-title section-space--bottom--">Our Services <span className="title-icon" /></h2>
                        <p className="section-space--bottom--30 video-cta-content__text w-100"><p>
                      Our experience in product development and manufacturing provides an end-to-end turnkey solution for companies seeking full product development services from a single source.
                    </p>
                    <p>With close to 7+ years of solid experience in Electronics Design, we have completed many high-tech & complex Electronic Product Designs. We believe in creating values by providing innovative and unparalleled services and solutions in the field of Electronic Manufacturing, Design & Engineering, Rapid Prototyping and New Product Introduction. We always strive to deliver high-quality services at affordable costs.
                    </p>
                    <p>
                    We have been recognized for our fast turnaround with “First-Time Right Design” techniques giving you faster time-to-market. The products that we have designed are field proven & working successfully 24x7 over several years, Our Designs are always exceeding customer expectations.
                    </p><span className="title-icon pb-0" /></p>
                        </div>
                        
                    </div>
                    <div className="col-lg-12">
                        {/* service tab wrapper */}
                        
                        <div className="service-tab-wrapper">
                        <Tabs>
                            <div className="row no-gutters">
                                <div className="col-md-4">
                                    <TabList>
                                        <div className="service-tab__link-wrapper">
                                            {serviceTabMenuDatalist}
                                        </div>
                                    </TabList>
                                </div>

                                <div className="col-md-8">
                                    {serviceTabContentDatalist}
                                </div>
                            </div>
                        </Tabs>
                        </div>
                    </div>
                    <p className="section-space--top--30 text-center video-cta-content__text w-100"><span className="title-icon pb-0" />Hallmark PCB services is the product engineering team, an product manufacturer located in India.We have 
5+ years of experience in product development and manufacturing.</p>
                    </div>
                </div>
                </div>
                {/*====================  End of service tab area  ====================*/}
            </div>
        )
    }
}

export default ServiceTabExample;