import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Calltoactionsix extends Component{

    render(){
        
        /* service tab menu */
        let ServiceTabMenuData = [
            {iconName: 'flaticon-002-welding', tabMenuName: 'PCB LAYOUT'},
            {iconName: 'flaticon-004-walkie-talkie', tabMenuName: 'PCB FABRICATION'},
            {iconName: 'flaticon-015-cart', tabMenuName: 'ASSEMBLY'},
            {iconName: 'flaticon-010-tank-1', tabMenuName: 'SOURCING'}
        ];

        let ServiceTabMenuDatalist = ServiceTabMenuData.map((val, i)=>{
            return(
                <Tab key={i}>  <span className="icon"><i className={val.iconName} /></span> <span className="text">{val.tabMenuName}</span></Tab>
            )
        });

        
        /* service tab content */
        
        let ServiceTabContentData = [
            {bgUrl: "service-tab1.jpg", contentTitle: 'PCB LAYOUT', contentDesc: 'Lorem ipsum dolor sit amet, consectet adipisicin elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', serviceLink: 'service-details-left-sidebar'},
            {bgUrl: "service-tab1.jpg", contentTitle: 'PCB FABRICATION', contentDesc: 'Lorem ipsum dolor sit amet, consectet adipisicin elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', serviceLink: 'service-details-left-sidebar'},
            {bgUrl: "service-tab1.jpg", contentTitle: 'ASSEMBLY', contentDesc: 'Lorem ipsum dolor sit amet, consectet adipisicin elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', serviceLink: 'service-details-left-sidebar'},
            {bgUrl: "service-tab1.jpg", contentTitle: 'SOURCING', contentDesc: 'Lorem ipsum dolor sit amet, consectet adipisicin elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', serviceLink: 'service-details-left-sidebar'}
        ];

        let ServiceTabContentDatalist = ServiceTabContentData.map((val, i)=>{
            return(
                <TabPanel key={i}>
                    <div className="service-tab__single-content-wrapper" style={{ backgroundImage: `url(assets/img/backgrounds/${val.bgUrl})` }}>
                        <div className="service-tab__single-content">
                            <h3 className="service-tab__title">{val.contentTitle}</h3>
                            <p className="service-tab__text">{val.contentDesc}</p>
                            <a href={`${process.env.PUBLIC_URL}/${val.serviceLink}`} className="see-more-link">SEE MORE</a>
                        </div>
                    </div>
                </TabPanel>
            )
        });

        return(
            <div>

                {/*====================  service tab area ====================*/}
                <div className="service-tab-area section-space--inner--80 grey-bg">
                <div className="container ">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                        <h2 className="section-title section-space--bottom--30">Are you interested in Turnkey Product Development ?</h2> <br></br>
                        <div>
                            <Link class="ht-btn ht-btn--round" to = {`${process.env.PUBLIC_URL}/contact-us`}>CONTACT US</Link>
                        </div>
                      </div>
                    </div>
                   
                   
                    </div>
                </div>
                </div>
                {/*====================  End of service tab area  ====================*/}
            </div>
        )
    }
}

export default Calltoactionsix;