import React, { Component } from 'react';
import ServiceGallery from "../service/components/ServiceGallery";
import {Link} from 'react-router-dom';
import ModalVideo from 'react-modal-video'

class VideoCta extends Component {
    render() {
      return (
        <div>
  
          {/*====================  End of breadcrumb area  ====================*/}
  
          <div className="page-wrapper section-space--inner--60">
            {/*Service section start*/}
            <div className="service-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 my-2 col-12 order-1 order-lg-2" data-aos="fade-up">
                    <div className="video-cta-content">
                    {/* <h4 className="video-cta-content__small-title">ABOUT US</h4> */}
                    <h3 className="video-cta-content__title">Electronic Design Service</h3>
                    <p className="video-cta-content__text mb-4">Hallmark PCB services is an electronic product design house offering end to end product development services. We help 
                    companies from ideation to manufacturing and scale their products from prototypes to large scale production.
                    </p>
                    <p className="video-cta-content__text">We have ability
                    to design Multilayer, High complexity, Highly dense PCBs. We are Experts in Reliable PCB Layout and Manufacturing Design that results in 
                    Cost-Effective Products.
                    </p>
                    <Link className="ht-btn ht-btn--round" to={`${process.env.PUBLIC_URL}/contact-us`}>CONTACT US</Link>
                    </div>
                  </div>
                  <div className="col-lg-6 my-2 col-12 order-1 order-lg-2">
                    <div className="service-details">
                      {/* service gallery */}
                      <ServiceGallery />
  
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-12 order-2 order-lg-1">
                    <Sidebar />
                  </div> */}
                </div>
              </div>
            </div>
            {/*Service section end*/}
          </div>
        </div>
      );
    }
  }


export default VideoCta;