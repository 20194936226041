import React, { Component } from "react";
import {Link} from 'react-router-dom';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class Faq extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>FAQ’s</h1>
                  {/* <ul className="page-breadcrumb">
                    <li>
                    <Link to = {`${process.env.PUBLIC_URL}/home-two`}>Home</Link>
                    </li>
                    <li>FAQ’s</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  content page content ====================*/}
        <div className="page-wrapper section-space--inner--60">
          {/*Contact section start*/}
          <div className="conact-section">
            <div className="container">
              <div className="wrapper">
                <div className="accordion accordion-flush" id="myAccordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        WHAT FILES AND DOCUMENTS DO YOU REQUEST FOR MY PCBA
                        ORDERS?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3">
                        <p>
                          We need Gerber files, Centroid data and BOM for your
                          PCBA orders. If you have already placed your PCB order
                          with us, actually you only need to send the later two
                          if your PCB Gerber files have included the layers of
                          silkscreen, copper track and solder paste. If your PCB
                          Gerber files are missing any of the above-mentioned
                          three layers, please resend it, as this is the minimum
                          request for PCBA. For the best possible result, please
                          also send assembly drawings, instructions and photos
                          to us to avoid any ambiguous and even mistaken
                          placement of parts, though these are not required by
                          most of the assemblers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingtwo">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsetwo"
                        aria-expanded="false"
                        aria-controls="flush-collapsetwo"
                      >
                        WHERE SHOULD I CHECK FOR ORDER ACKNOWLEDGEMENT /
                        PROFORMA INVOICE ?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsetwo"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapsetwo"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Hallmark PCB Services has system generated email will
                          be sent to you with attachment of OA/PI copy through
                          sales@hallmarkpcb.com
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingthree">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsethree"
                        aria-expanded="false"
                        aria-controls="flush-collapsethree"
                      >
                        CAN I SEND YOU SOME COMPONENTS FOR MY FUTURE ASSEMBLY
                        ORDERS?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsethree"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapsethree"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Yes. However, before you send them to us, please
                          contact us with full details of the shipping
                          information, quantity, and part numbers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingfour">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsefour"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        WHEN YOU PRODUCE THE FIRST ASSEMBLY, COULD YOU SEND ME
                        SOME PHOTOS TO CONFIRM THE FINAL PRODUCT?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsefour"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapsefour"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Yes. If you would like to confirm the final product
                          before manufacture, please leave a note "Send me
                          photos of the first assembled board to confirm" in the
                          BOM file or just send this request through email when
                          quote.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingfive">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsefive"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        WHAT IS THE EXPECTED DELIVERY TIME FOR A TURN-KEY PCBA
                        ORDER?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsefive"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapsefive"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          The general lead time for the fulfillment of PCBA
                          orders is about 2-5 weeks. This includes PCB
                          manufacture, component procurement, and assembly.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingsix">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsesix"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        IS THERE ANY MINIMUM ORDER QUANTITY (MOQ) FOR THE PCBA
                        ORDER?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsesix"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapsesix"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          No, there is no MOQ for PCBA on our side; the order
                          quantity is totally up to you.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingseven">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseseven"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        CAN YOU ENSURE THE QUALITY OF THE PARTS YOU USE?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseseven"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseseven"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          We purchase all our parts from reputable supplyer like
                          DigiKey, Mouser, Semikart, element14. As such, we can
                          guarantee the quality of the parts we use. We also
                          have a quality control department that verifies the
                          quality of all parts before they are incorporated into
                          our products.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingeight">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseeight"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        CAN YOU SOURCE SOME PARTS FOR MY ASSEMBLY?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseeight"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseeight"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Yes. This practice is called partial Turn-key. You can
                          supply some parts, and we source the rest of the parts
                          on your behalf. We will ask for your approval for
                          anything that is not sure at our side. In case parts
                          crossing or substitution is needed, we will again ask
                          for your final approval.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingnine">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsenine"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        DO YOU ASSEMBLE BGA? ANY SPECIAL REQUIREMENT ON THIS?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsenine"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapsenine"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Yes, We have the ability to handle BGA of 0.25mm pitch
                          with X-ray testing. For BGAs, we need to tent any vias
                          under the BGAs with solder mask to ensure the
                          soldering quality.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingten">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseten"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        HOW DO YOU QUOTE THE TOTAL PRICE OF PCBA SERVICE?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseten"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseten"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Our online instant quote system gives the full idea of
                          the cost of PCBA which include the, Framed laser cut
                          stencil with the dimension (29' x 29')
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingeleven">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseeleven"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        ARE YOU CHARGING SETUP COST FOR PCBA SERVICE?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseeleven"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseeleven"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          No, we are not charging any setup cost on PCBA
                          service, Our online PCB assembly price calculator is
                          working on Qty based concept.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingeleone">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseeleone"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        HOW CAN I GET A QUOTE FOR OTHER SERVICES?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseeleone"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseeleone"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          At hallmarkpcb.com, you can request for quote, options
                          are available. Once we received your request our
                          service person will quote within 24 hours, do not
                          forget to enclose your Gerber files and all other
                          necessary files for PCBA, PCB, STENCIL, quotation.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingeletwo">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseeletwo"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        HOW CAN I PAY FOR THE ORDER? / WHAT PAYMENT METHODS ARE
                        AVAILABLE?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseeletwo"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseeletwo"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Our preferred payment method is UPI payment mode,
                          Accept debit and credit cards from all card
                          associations, 50+ netbanking.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingelethree">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseelethree"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        WHAT SHIPPING METHODS DO YOU USE? WHAT IS THE STANDARD
                        DELIVERY TIME?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseelethree"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseelethree"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Generally we are using Bluedart and Nandan courier
                          service.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingelefour">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseelefour"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        WHAT IS MINIMUM AND MAXIMUM SIZE OF PCB FOR PCB
                        ASSEMBLY?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseelefour"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseelefour"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Minimum size for PCB in PCB Assembly is 50*50mm and
                          Maximum size is 400*500mm, when your PCB is of any
                          shapes (circular, or odd shape) other than rectangle,
                          your boards must be panellized in an array for
                          assembly for this case we need to check the design for
                          feasibility.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingelefive">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseelefive"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        WHAT FILES DO YOU NEED? / DO YOU ACCEPT FILES IN BRD
                        FORMAT?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseelefive"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseelefive"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          As a default, we accept Gerber file RS-274X format.
                          (Note: 274D format is not accepted) We also accept the
                          following layout files:*.ODB++, PROTEL *.PCB/ *.PCBDOC
                          EAGLE *.BRD (IMPORTANT: please provide your Eagle
                          Version to avoid conversion mistakes).
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingelesix">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseelesix"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        WHAT FILES AND DOCUMENTS DO YOU REQUEST FOR MY PCBA
                        ORDERS?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseelesix"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseelesix"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          We need Gerber files, Centroid data and BOM for your
                          PCBA orders. As have already placed your PCB order
                          with us, actually you only need to send the latter two
                          if your PCB Gerber files have included the layers of
                          silkscreen, copper track and solder paste. If your PCB
                          Gerber files are missing any of the above-mentioned
                          three layers, please resend them, as this is the
                          minimum request for PCBA. For the best possible
                          result, please also send assembly drawings,
                          instructions and photos to us to avoid any ambiguous
                          and even mistaken placement of parts, though these are
                          not required by most of the assemblers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingeleseven">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseeleseven"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        WHAT IS CENTROID?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseeleseven"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseeleseven"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Centroid is the special file for assembly used for
                          quickly programming the assembly machines. This is
                          also known as aka Insertion, Pick-N-Place, or XY Data.
                          Some of the CAD tools will automatically generate this
                          file and some not, but you may need to modify the file
                          and then generate the Centroid file. The Centroid file
                          describes the position and orientation of all the
                          surface mount parts, which includes the reference
                          designator, X and Y position, rotation and side of
                          Board (Top or Bottom). Only surface mounting parts are
                          listed in the Centroid.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingeleeight">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseeleeight"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        IS THE PRICE IS SAME FOR ROHS AND NON ROHS PCB ASSEMBLY?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseeleeight"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseeleeight"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Yes, price is same for the both option but you must
                          have to mention if you require ROHS or NON ROHS before
                          order.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingfourelenine">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsefourelenine"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        DOES YOU REQUIRE EXTRA COMPONENTS IN PCBA ORDER?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsefourelenine"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapsefourelenine"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          Yes We need 10% Extra components including Passive
                          components and Some small Ic's category.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingfoureleten">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsefoureleten"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        HOW DOES YOUR PRIVACY POLICY WORK?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsefoureleten"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapsefoureleten"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          We respect the privacy of all our customers. We
                          promise we will never share your personal information
                          with any third parties.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingeleele">
                      <button
                        className="accordion-button collapsed border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseeleele"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        HOW CAN I CONTACT YOU?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseeleele"
                      className="accordion-collapse collapse border-0"
                      aria-labelledby="flush-collapseeleele"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body px-5 pt-3 pb-3 p-2">
                        <p>
                          For technical issues, please email our technicians at
                          sales@hallmarkpcb.com.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Contact section end*/}
        </div>
        {/*====================  End of content page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Faq;
