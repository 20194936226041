import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
class Requestquotetab extends Component {
  render() {
    /* service tab menu */
    let serviceTabMenuData = [{ tabMenuName: "PCB DESIGN" }];
    let serviceTabMenuDataa = [{ tabMenuName: "PCB FABRICATION" }];
    let serviceTabMenuDataaa = [{ tabMenuName: "PCB ASSEMBLY" }];
    let serviceTabMenuDataaaa = [{ tabMenuName: "COMPONENT SOURCING" }];

    let serviceTabMenuDatalist = serviceTabMenuData.map((val, i) => {
      return (
        <Tab>
          {" "}
          <span className="text">{val.tabMenuName}</span>
        </Tab>
      );
    });
    let serviceTabMenuDatalistt = serviceTabMenuDataa.map((val, j) => {
      return (
        <Tab>
          {" "}
          <span className="text">{val.tabMenuName}</span>
        </Tab>
      );
    });
    let serviceTabMenuDatalisttt = serviceTabMenuDataaa.map((val, k) => {
      return (
        <Tab>
          {" "}
          <span className="text">{val.tabMenuName}</span>
        </Tab>
      );
    });
    let serviceTabMenuDatalistttt = serviceTabMenuDataaaa.map((val, l) => {
      return (
        <Tab>
          {" "}
          <span className="text">{val.tabMenuName}</span>
        </Tab>
      );
    });

    /* service tab content */

    let serviceTabContentData = [
      { bgUrl: "service-tab1.jpg", contentTitle: "PCB DESIGN" },
    ];
    let serviceTabContentDataa = [
      { bgUrl: "service-tab1.jpg", contentTitle: "PCB FABRICATION" },
    ];
    let serviceTabContentDataaa = [
      { bgUrl: "service-tab1.jpg", contentTitle: "PCB ASSEMBLY" },
    ];
    let serviceTabContentDataaaa = [
      { bgUrl: "service-tab1.jpg", contentTitle: "COMPONENT SOURCING" },
    ];

    let serviceTabContentDatalist = serviceTabContentData.map((val, i) => {
      return (
        <TabPanel key={i} className="ml-0">
          <div className="service-tab__single-content-wrapper p-0">
            <div className="service-tab__single-content w-100 p-0">
              <div class=" p-4 bg-white ">
                <div class="contact-form ">
                  <form id="contact-form">
                    <div class="row row-10">
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Customer Name :</label>
                        <input
                          className="whyborderr"
                          name="Name"
                          type="text"
                          placeholder="Customer Name"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Company Name :</label>
                        <input
                          className="whyborderr"
                          name="Company_Name"
                          type="text"
                          placeholder="Company Name"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Email :</label>
                        <input
                          className="whyborderr"
                          name="Email"
                          type="email"
                          placeholder="Email"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Mobile :</label>
                        <input
                          className="whyborderr"
                          name="Mobile"
                          type="number"
                          placeholder="Mobile"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Layer :</label>
                        <select
                          class="form-select whyborder rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>1 Layer</option>
                          <option value="1">2 Layer</option>
                          <option value="2">4 Layer</option>
                          <option value="3">6 Layer</option>
                          <option value="1">8 Layer</option>
                          <option value="2">10 Layer</option>
                          <option value="3">12 Layer</option>
                        </select>
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Lead Time :</label>
                        <select
                          class="form-select whyborder rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>3 Working Day</option>
                          <option value="1">5 Working Day</option>
                          <option value="2">12 Working Day</option>
                        </select>
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">
                          Number Of Components :
                        </label>
                        <div className="row">
                          <div className="col-6">
                            <input
                              type="radio"
                              class="btn-check whyborder"
                              name="options-outlined"
                              id="success-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="success-outlined"
                            >
                              75 Or Less
                            </label>
                          </div>
                          <div className="col-6">
                            <input
                              type="radio"
                              class="btn-check whyborder"
                              name="options-outlined"
                              id="danger-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="danger-outlined"
                            >
                              More Then 75
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Control Impedance :</label>
                        <div className="row">
                          <div className="col-6">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options-outlinedd"
                              id="successs-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="successs-outlined"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="col-6">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options-outlinedd"
                              id="dangerr-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="dangerr-outlined"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Schemaic :</label>
                        <div className="form-file">
                          <label
                            className="d-flex align-items-center justify-content-center flex-column text-dark drag whyborder"
                            for="upload"
                          >
                            <i
                              class="fs-2 zmdi zmdi-plus-circle-o"
                              style={{ color: "darkgray" }}
                            ></i>
                            <span
                              className="pt-1"
                              style={{ color: "darkgray" }}
                            >
                              Upload Schemaic
                            </span>
                          </label>
                          <input type="file" id="upload" hidden />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Bill Of Mateial :</label>
                        <div className="form-file">
                          <label
                            className="d-flex align-items-center justify-content-center flex-column text-dark drag whyborder"
                            for="upload"
                          >
                            <i
                              class="fs-2 zmdi zmdi-plus-circle-o"
                              style={{ color: "darkgray" }}
                            ></i>
                            <span
                              className="pt-1"
                              style={{ color: "darkgray" }}
                            >
                              Upload Bill Of Mateial
                            </span>
                          </label>
                          <input type="file" id="upload" hidden />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">
                          Miscellaneous Mateial :
                        </label>
                        <div className="form-file">
                          <label
                            className="d-flex align-items-center justify-content-center flex-column text-dark drag whyborder"
                            for="upload"
                          >
                            <i
                              class="fs-2 zmdi zmdi-plus-circle-o"
                              style={{ color: "darkgray" }}
                            ></i>
                            <span
                              className="pt-1"
                              style={{ color: "darkgray" }}
                            >
                              Upload Miscellaneous Mateial
                            </span>
                          </label>
                          <input type="file" id="upload" hidden />
                        </div>
                      </div>
                      {/* <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                                                <div className='form-file'>
                                                    <label className='d-flex align-items-center justify-content-center flex-column text-dark drag whyborder' for="upload"><i class="fs-2 zmdi zmdi-check-circle-u" style={{color:'#009933'}}></i><span className='pt-1' style={{color:'#009933'}}>File Successfully Uploaded</span></label>
                                                    <input type="file" id="upload" hidden/>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                                                <div className='form-file'>
                                                    <label className='d-flex align-items-center justify-content-center flex-column text-dark drag whyborder' for="upload"><i class="fs-2 zmdi zmdi-check-circle-u" style={{color:'#009933'}}></i><span className='pt-1' style={{color:'#009933'}}>File Successfully Uploaded</span></label>
                                                    <input type="file" id="upload" hidden/>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                                                <div className='form-file'>
                                                    <label className='d-flex align-items-center justify-content-center flex-column text-dark drag whyborder' for="upload"><i class="fs-2 zmdi zmdi-check-circle-u" style={{color:'#009933'}}></i><span className='pt-1' style={{color:'#009933'}}>File Successfully Uploaded</span></label>
                                                    <input type="file" id="upload" hidden/>
                                                </div>
                                            </div> */}
                      {/* <div class="col-12">
                                            <Link className="whyborder ht-btn ht-btn--default ht-btn--default--dark-hove whyborder" to={`${process.env.PUBLIC_URL}/thankyou`}>SUBMIT</Link>
                                            </div> */}
                    </div>
                  </form>
                  <div class="col-12">
                    <button
                      className="ht-btn ht-btn--default ht-btn--default--dark-hover whyborder"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      SUBMIT
                    </button>
                    {/* <a href={`${process.env.PUBLIC_URL}/thankyou`} className="whyborder ht-btn ht-btn--default ht-btn--default--dark-hover">SUBMIT</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      );
    });
    let serviceTabContentDatalistt = serviceTabContentDataa.map((val, j) => {
      return (
        <TabPanel key={j} className="ml-0">
          <div className="service-tab__single-content-wrapper p-0">
            <div className="service-tab__single-content w-100 p-0">
              <div class=" p-4 bg-white ">
                <div class="contact-form ">
                  <form id="contact-form">
                    <div class="row row-10">
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Customer Name :</label>
                        <input
                          className="whyborderr"
                          name="Name"
                          type="text"
                          placeholder="Customer Name"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Company Name :</label>
                        <input
                          className="whyborderr"
                          name="Company_Name"
                          type="text"
                          placeholder="Company Name"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Email :</label>
                        <input
                          className="whyborderr"
                          name="Email"
                          type="email"
                          placeholder="Email"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Mobile :</label>
                        <input
                          className="whyborderr"
                          name="Mobile"
                          type="number"
                          placeholder="Mobile"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Board Size (MM) :</label>
                        <div className="row align-items-center">
                          <div className="col-5">
                            <input
                              className="whyborderr"
                              name="Mobile"
                              type="number"
                              placeholder="Size X"
                            />
                          </div>
                          <div className="col-2 text-dark text-center fs-3">
                            X
                          </div>
                          <div className="col-5">
                            <input
                              className="whyborderr"
                              name="Mobile"
                              type="number"
                              placeholder="Size Y"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Dispatch Unit :</label>
                        <div className="row">
                          <div className="col-6">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options-outlined"
                              id="success-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="success-outlined"
                            >
                              Single Board
                            </label>
                          </div>
                          <div className="col-6">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options-outlined"
                              id="danger-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="danger-outlined"
                            >
                              Paner
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">PCB Layer :</label>
                        <select
                          class="form-select whyborder rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>1 Layer</option>
                          <option value="1">2 Layer</option>
                          <option value="2">4 Layer</option>
                          <option value="3">More Layer</option>
                        </select>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Material :</label>
                        <select
                          class="form-select whyborder rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>FR4</option>
                          <option value="1">FR1</option>
                          <option value="2">CEM1</option>
                          <option value="3">CEM3</option>
                          <option value="1">Meal Clad</option>
                          <option value="2">Paper Phenlic</option>
                          <option value="3">12 Layer</option>
                        </select>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">PCB Lead Time :</label>
                        <select
                          class="form-select whyborder rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>3 Days</option>
                          <option value="1">5 Days</option>
                          <option value="2">7 Days</option>
                          <option value="2">9 Days</option>
                          <option value="2">12 Days</option>
                          <option value="2">17 Days</option>
                        </select>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">
                          PCB Thickness (MM) :
                        </label>
                        <select
                          class="form-select whyborder rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>0.4 MM</option>
                          <option value="1">0.8 MM</option>
                          <option value="2">1 MM</option>
                          <option value="3">1.2 MM</option>
                          <option value="1">1.6 MM</option>
                          <option value="2">2 MM</option>
                          <option value="3">2.4 MM</option>
                          <option value="3">3.2 MM</option>
                        </select>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Masking Colour :</label>
                        <select
                          class="form-select whyborder rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>Green</option>
                          <option value="1">No Mask</option>
                          <option value="2">Red</option>
                          <option value="3">White</option>
                          <option value="1">Black</option>
                          <option value="2">Blue</option>
                        </select>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">
                          Skil Screen / Legend :
                        </label>
                        <select
                          class="form-select whyborder rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>White</option>
                          <option value="1">No Skil Screen</option>
                          <option value="2">White</option>
                          <option value="3">Black</option>
                        </select>
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">
                          Finish Copper Thichness (Micro) :
                        </label>
                        <select
                          class="form-select whyborder rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>35 Micro</option>
                          <option value="1">70 Micro</option>
                          <option value="1">105 Micro</option>
                        </select>
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Surface Finish :</label>
                        <select
                          class="form-select whyborder rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>Normal HASL</option>
                          <option value="1">Lead Tin HASL</option>
                          <option value="2">HAL</option>
                          <option value="2">Lacauer</option>
                          <option value="2">Roller Tin Finish</option>
                          <option value="2">Electro Plated Gold</option>
                          <option value="2">Electro Plated Nickle</option>
                        </select>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">
                          Upload Geber Files (Zip , Rar File Allowed) :
                        </label>
                        <div className="form-file">
                          <label
                            className="d-flex align-items-center justify-content-center flex-column text-dark drag whyborder"
                            for="upload"
                          >
                            <i
                              class="fs-2 zmdi zmdi-plus-circle-o"
                              style={{ color: "darkgray" }}
                            ></i>
                            <span
                              className="pt-1"
                              style={{ color: "darkgray" }}
                            >
                              Upload Gerber Files
                            </span>
                          </label>
                          <input type="file" id="upload" hidden />
                        </div>
                      </div>
                      {/* <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                                                <div className='form-file'>
                                                    <label className='d-flex align-items-center justify-content-center flex-column text-dark drag whyborder' for="upload"><i class="fs-2 zmdi zmdi-check-circle-u" style={{color:'#009933'}}></i><span className='pt-1' style={{color:'#009933'}}>File Successfully Uploaded</span></label>
                                                    <input type="file" id="upload" hidden/>
                                                </div>
                                            </div> */}
                      {/* <div class="col-12">
                                            <Link className="whyborder ht-btn ht-btn--default ht-btn--default--dark-hover whyborder" to={`${process.env.PUBLIC_URL}/thankyou`}>SUBMIT</Link>
                                            </div> */}
                    </div>
                  </form>
                  <div class="col-12">
                    <button
                      className="ht-btn ht-btn--default ht-btn--default--dark-hover whyborder"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      SUBMIT
                    </button>
                    {/* <a href={`${process.env.PUBLIC_URL}/thankyou`} className="whyborder ht-btn ht-btn--default ht-btn--default--dark-hover">SUBMIT</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      );
    });
    let serviceTabContentDatalisttt = serviceTabContentDataaa.map((val, k) => {
      return (
        <TabPanel key={k} className="ml-0">
          <div className="service-tab__single-content-wrapper p-0">
            <div className="service-tab__single-content w-100 p-0">
              <div class=" p-4 bg-white ">
                <div class="contact-form ">
                  <form id="contact-form">
                    <div class="row row-10">
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Customer Name :</label>
                        <input
                          className="whyborderr"
                          name="Name"
                          type="text"
                          placeholder="Customer Name"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Company Name :</label>
                        <input
                          className="whyborderr"
                          name="Company_Name"
                          type="text"
                          placeholder="Company Name"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Email :</label>
                        <input
                          className="whyborderr"
                          name="Email"
                          type="email"
                          placeholder="Email"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Mobile :</label>
                        <input
                          className="whyborderr"
                          name="Mobile"
                          type="number"
                          placeholder="Mobile"
                        />
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">PCB Size (MM) :</label>
                        <div className="row align-items-center">
                          <div className="col-5">
                            <input
                              className="whyborderr"
                              name="Mobile"
                              type="number"
                              placeholder="Size X"
                            />
                          </div>
                          <div className="col-2 text-dark text-center fs-3">
                            X
                          </div>
                          <div className="col-5">
                            <input
                              className="whyborderr"
                              name="Mobile"
                              type="number"
                              placeholder="Size Y"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">DIP Pads :</label>
                        <input
                          className="whyborderr"
                          name="Name"
                          type="text"
                          placeholder="DIP Pads"
                        />
                      </div>
                      <div class="col-lg-3 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">SMD Pads :</label>
                        <input
                          className="whyborderr"
                          name="Name"
                          type="text"
                          placeholder="SMD Pads"
                        />
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Board Qty :</label>
                        <input
                          className="whyborderr"
                          name="Name"
                          type="text"
                          placeholder="Board Qty"
                        />
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Assembly Side :</label>
                        <select
                          class="form-select whyborder rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>Single Side</option>
                          <option value="1">Double Side</option>
                        </select>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">
                          No. Of BGN / QFP / QPN :
                        </label>
                        <input
                          className="whyborderr"
                          name="Name"
                          type="text"
                          placeholder="No. Of BGN / QFP / QPN"
                        />
                      </div>
                      <div class="col-12 section-space--bottom--20">
                        <label className="text-dark">BOM Parts Options :</label>
                        <div className="row">
                          <div className="col-lg-4 col-md-6 col-12">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options-outlinedd"
                              id="successs-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="successs-outlined"
                            >
                              <span>We Supply</span>
                              <hr className="m-1" />
                              <span>
                                Components Supply By Hallmark PCB Services
                              </span>
                            </label>
                          </div>
                          <div className="col-lg-4 col-md-6 col-12">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options-outlinedd"
                              id="dangerr-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="dangerr-outlined"
                            >
                              <span>Customer Supply</span>
                              <hr className="m-1" />
                              <span>Components Supply By Customer</span>
                            </label>
                          </div>
                          <div className="col-lg-4 col-md-6 col-12">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options-outlinedd"
                              id="dang-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="dang-outlined"
                            >
                              <span>Combo Solution</span>
                              <hr className="m-1" />
                              <span>Components Supply By Both Parties</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">
                          Do You Accept Alternative / Substitute If Some Parts
                          Are Not Available ? :
                        </label>
                        <div className="row">
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options-outlined"
                              id="success-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="success-outlined"
                            >
                              No
                            </label>
                          </div>
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options-outlined"
                              id="danger-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="danger-outlined"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-12 section-space--bottom--20">
                        <label className="text-dark">
                          Contains Tempratre And Hmdity Sensitive Components ? :
                        </label>
                        <div className="row">
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options-outlineddd"
                              id="successsss-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="successsss-outlined"
                            >
                              No
                            </label>
                          </div>
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options-outlineddd"
                              id="dangerrr-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="dangerrr-outlined"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr className="text-dark mb-3" />
                      <div class="col-12 section-space--bottom--20">
                        <label className="text-dark">X-ray Test / Qty :</label>
                        <input
                          className="whyborderr"
                          name="Name"
                          type="text"
                          placeholder="X-ray Test / Qty"
                        />
                      </div>
                      <div class="col-lg-3 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Program Burn :</label>
                        <div className="row">
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options"
                              id="y1-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="y1-outlined"
                            >
                              No
                            </label>
                          </div>
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="options"
                              id="n1-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="n1-outlined"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Depane | The PCB :</label>
                        <div className="row">
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="outlineddd"
                              id="y2-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="y2-outlined"
                            >
                              No
                            </label>
                          </div>
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="outlineddd"
                              id="n2-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="n2-outlined"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Function Test :</label>
                        <div className="row">
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="opt"
                              id="y3-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="y3-outlined"
                            >
                              No
                            </label>
                          </div>
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="opt"
                              id="n3-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="n3-outlined"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-6 col-12 section-space--bottom--20">
                        <label className="text-dark">Add Solder-Paste :</label>
                        <div className="row">
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="oppt"
                              id="y4-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="y4-outlined"
                            >
                              No
                            </label>
                          </div>
                          <div className="col-4">
                            <input
                              type="radio"
                              class="btn-check"
                              name="oppt"
                              id="n4-outlined"
                              autocomplete="off"
                            />
                            <label
                              class="w-100 rounded-0 btn btn-outline-success whyborder"
                              for="n4-outlined"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* <div class="col-12">
                                        <Link className="whyborder ht-btn ht-btn--default ht-btn--default--dark-hover whyborder" to={`${process.env.PUBLIC_URL}/thankyou`}>SUBMIT</Link>
                                        </div> */}
                    </div>
                  </form>
                  <div class="col-12">
                    <button
                      className="ht-btn ht-btn--default ht-btn--default--dark-hover whyborder"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      SUBMIT
                    </button>
                    {/* <a href={`${process.env.PUBLIC_URL}/thankyou`} className="whyborder ht-btn ht-btn--default ht-btn--default--dark-hover">SUBMIT</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      );
    });
    let serviceTabContentDatalistttt = serviceTabContentDataaaa.map(
      (val, l) => {
        return (
          <TabPanel key={l} className="ml-0">
            <div className="service-tab__single-content-wrapper p-0">
              <div className="service-tab__single-content w-100 p-0">
                <div class=" p-4 bg-white ">
                  <div class="contact-form ">
                    <form id="contact-form">
                      <div class="row row-10">
                        <div class="col-lg-6 col-12 section-space--bottom--20">
                          <label className="text-dark">Customer Name :</label>
                          <input
                            className="whyborderr"
                            name="Name"
                            type="text"
                            placeholder="Customer Name"
                          />
                        </div>
                        <div class="col-lg-6 col-12 section-space--bottom--20">
                          <label className="text-dark">Company Name :</label>
                          <input
                            className="whyborderr"
                            name="Company_Name"
                            type="text"
                            placeholder="Company Name"
                          />
                        </div>
                        <div class="col-lg-6 col-12 section-space--bottom--20">
                          <label className="text-dark">Email :</label>
                          <input
                            className="whyborderr"
                            name="Email"
                            type="email"
                            placeholder="Email"
                          />
                        </div>
                        <div class="col-lg-6 col-12 section-space--bottom--20">
                          <label className="text-dark">Mobile :</label>
                          <input
                            className="whyborderr"
                            name="Mobile"
                            type="number"
                            placeholder="Mobile"
                          />
                        </div>
                        <div class="col-lg-6 col-12 section-space--bottom--20">
                          <label className="text-dark">Purchase Timing :</label>
                          <select
                            class="form-select whyborder rounded-0"
                            aria-label="Default select example"
                          >
                            <option selected>Immidiate Buy</option>
                            <option value="1">Future Buy</option>
                            <option value="2">Budgetary Purpose</option>
                          </select>
                        </div>
                        <div class="col-12 mb-4">
                          <label className="text-dark">Message :</label>
                          <textarea
                            className="whyborderr"
                            name="Message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                      </div>
                    </form>
                    <div class="col-12">
                      <button
                        className="ht-btn ht-btn--default ht-btn--default--dark-hover whyborder"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        SUBMIT
                      </button>
                      {/* <a href={`${process.env.PUBLIC_URL}/thankyou`} className="whyborder ht-btn ht-btn--default ht-btn--default--dark-hover">SUBMIT</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        );
      }
    );

    return (
      <div>
        {/*==================  service tab area ====================*/}
        <div className="service-tab-area section-space--inner--50">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* service tab wrapper */}

                <div className="service-tab-wrapper">
                  <Tabs>
                    <div className="">
                      <TabList>
                        <div className="service-tab__link-wrapper d-flex justify-content-evenly align-items-baseline flex-wrap">
                          <div class="col-12 why3" id="">
                            <div class="about-content-two row">
                              <div class="col-lg-3 col-md-6 col-12 my-3">
                                <div class="image-hover  border whyborder rounded text-center p-3 h-100">
                                  <div class="image-cont">
                                    <p class="mb-0 py-3 fs-6 fw-bold video-cta-content__text w-100">
                                    PCB DESIGN
                                    </p>
                                    <p class="text">
                                    Want to go for PCB Design, Click here to send your detail
                                    </p>
                                    <a className="ht-btn ht-btn--round d-inline-block fw-bold mt-2 py-3 px-5" href="https://mechodalgroup.xyz/HallMark/pcbdesign.php">SEND QUOTE</a>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 my-3">
                                <div class="image-hover border  whyborder rounded text-center p-3 h-100">
                                  <div class="image-cont">
                                    <p class="mb-0 py-3 fs-6 fw-bold video-cta-content__text w-100">
                                    PCB FABRICATION
                                    </p>
                                    <p class="text">
                                    Want to go for PCB Fabrication, Click here to send your detail
                                    </p>
                                    <a className='ht-btn ht-btn--round d-inline-block fw-bold mt-2 py-3 px-5' href='https://mechodalgroup.xyz/HallMark/pcbfabrication.php'>SEND QUOTE</a>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 my-3">
                                <div class="image-hover border  whyborder rounded text-center p-3 h-100">
                                  <div class="image-cont">
                                    <p class="mb-0 py-3 fs-6 fw-bold video-cta-content__text w-100">
                                    PCB ASSEMBLY
                                    </p>
                                    <p class="text">
                                    Want to go for PCB Assembly, Click here to send your detail
                                    </p>
                                    <a className='ht-btn ht-btn--round d-inline-block fw-bold mt-2 py-3 px-5' href='https://mechodalgroup.xyz/HallMark/pcbassmly.php'>SEND QUOTE</a>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 my-3">
                                <div class="image-hover border  whyborder rounded text-center p-3 h-100">
                                  <div class="image-cont">
                                    <p class="mb-0 py-3 fs-6 fw-bold video-cta-content__text w-100">
                                    COMPONENT SOURCING
                                    </p>
                                    <p class="text">
                                    Want to go for PCB Sourcing, Click here to send your detail 
                                    </p>
                                    <a className='ht-btn ht-btn--round d-inline-block fw-bold mt-2 py-3 px-5' href='https://mechodalgroup.xyz/HallMark/component.php'>SEND QUOTE</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            {/* <a className="my-2" href="https://mechodalgroup.xyz/HallMark/pcbdesign.php">{serviceTabMenuDatalist}</a>
                            <a className='my-2' href='https://mechodalgroup.xyz/HallMark/pcbfabrication.php'>{serviceTabMenuDatalistt}</a>
                            <a className='my-2' href='https://mechodalgroup.xyz/HallMark/pcbassmly.php'>{serviceTabMenuDatalisttt}</a>
                            <a className='my-2' href='https://mechodalgroup.xyz/HallMark/component.php'>{serviceTabMenuDatalistttt}</a> */}
                        </div>
                      </TabList>
                    </div>
                    <div className="row no-gutters">
                      <div className="col-12">
                        {/* {serviceTabContentDatalist} */}
                        {/* {serviceTabContentDatalistt} */}
                        {/* {serviceTabContentDatalisttt} */}
                        {/* {serviceTabContentDatalistttt} */}
                      </div>
                    </div>
                  </Tabs>
                </div>

                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div className="error-page-content-wrap text-center">
                          <h4 className="mb-3 fs-4">
                            THANK YOU FOR PLACING QUOTATION REQUEST AT HALLMARK
                            PCB SERVICES
                          </h4>
                          <p className="mb-2 fs-5 fw-bold">
                            We Will Revert You Back Soon
                          </p>
                          <p className="mb-2 fs-5">
                            You Can Also Call Us On{" "}
                            <a href="tel:8154 8154 02">(+91) 8154 8154 02</a>
                          </p>
                          <p className="fs-6">
                            ( MON - FRI [10:00 AM To 06:00 PM] )
                          </p>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="ht-btn ht-btn--default ht-btn--default--dark-hover whyborder"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of service tab area  ====================*/}
      </div>
    );
  }
}

export default Requestquotetab;
