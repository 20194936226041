import React, { Component } from "react";
import {Link} from 'react-router-dom';
import NavBar from "../components/NavBar";
import FeatureIcon from "../components/FeatureIcon";
import Funfact from "../components/Funfact";
import TeamMemberGrid from "../components/TeamMemberGrid";
import TestimonialSlider from "../components/TestimonialSlider";
import ComponentBrand from "../components/ComponentBrand";
import Pcbassemblyjob from "../components/Pcbassemblyjob";
import Pcbinsertion from "../components/Pcbinsertion";
import Pcbaddservice from "../components/Pcbaddservice";
import Pcbnote from "../components/Pcbnote";
import Pcbdesignslider from "../components/Pcbdesignslider";
import Calltoactionfour from '../components/Calltoactionfour';
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ModalVideo from "react-modal-video";
class Reverseservice extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    let data = [
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "SMT Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-2.jpg",
        projectTitle: "BGA Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "Through-Hole Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "Mixed Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-3.jpg",
        projectTitle: "Rigid Flex PCB Assembly Services",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                  <img
                    src={`assets/img/projects/${val.img}`}
                    className="img-fluid w-100"
                    alt=""
                  />
              </div>
            </div>
            <div className="service-grid-item__content p-3">
              <h3 className="title m-0">
                  {val.projectTitle}
              </h3>
              {/* <a
                href={`${process.env.PUBLIC_URL}/${val.pageLink}`}
                className="see-more-link"
              >
                SEE MORE
              </a> */}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>PCB Reverse Engineering </h1>
                  {/* <ul className="page-breadcrumb">
                    <li>
                    <Link to = {`${process.env.PUBLIC_URL}/home-two`}>Home</Link>
                    </li>
                    <li>PCB Reverse Engineering</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--0 pt-4">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-3">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Reverse Engineering <br></br> Are you interested in PCB Reverse Engineering?</h3>
                    <p>
                    In electronics, reverse engineer refers to PCB boards that are rebuilt from assembled products or circuit boards that may potentially be faulty and need replacing, or new PCBs designed to mimic the functionality of th of those you already have.
                    </p>
                    {/* <a
                      href="services"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Learn More
                    </a> */}
                  </div>
                </div>
                
                
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/about/re-1.jpeg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two">
                    <h3>PCB Reverse Engineering and Redesign :</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>What Is PCB Reverse Engineering?</h3>
                    <p>
                    Customers often come to us with a board or a finished product and require more of the same boards to be reproduced, this is where our electronic reverse engineering services come into play.
                    </p>
                    <p>
                    In electronics, reverse engineer refers to PCB boards that are rebuilt from assembled products or circuit boards that may potentially be faulty and need replacing, or new PCBs designed to mimic the functionality of those you already have.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/projects/re1.jpeg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
           {/*About section start*/}
           <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>How Do Reverse Engineer a PCB?</h3>
                    <p>
                    We used technology to computer scan artwork, photos or circuit boards to produce CAD data for photo plotting to enable new PCB’s to be produced.
                    </p>
                    <p>
                    This is a much more economical way of replacing the board and saves the costs involved in re-designing the CAD layout. It also ensures the new PCB will function just like the original, while removing any risk of errors.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/projects/rev3.jpg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
           {/*About section start*/}
           <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Why not make an exact copy of the original PCB?</h3>
                    <p>
                    If we were to copy the board exactly, it would be both time consuming and expensive to source the old components previously used, rather than using new, improved components that are readily available.
                    </p>
                    <p>
                    So, whilst the new boards are produced to function and perform in the same way, or often better than the original, they are not an exact replica.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/projects/rev4.jpg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two  mb-4">
                    <h3>What are the benefits to Reverse Engineering a PCB?</h3>
                  </div>
                  <div className="container   ">
                        <h3 className="team-job__single">Maintain crucial PCBs:</h3>
                        <p className="ms-3 mb-4">At times, you may have an outdated PCB that performs a necessary function, such as powering a piece of machinery or an electronic component. If the PCB fails or needs to be replaced, you may not be able to find a replacement or know how to repair it. Reverse engineering the outdated PCB can help you in both these situations, allowing you to maintain crucial PCBs for a long time and potentially clone them to serve as replacements when repairs aren’t possible.</p>
                  </div>
                  <div className="container   ">
                        <h3 className="team-job__single">Get information on PCBs without documentation:</h3>
                        <p className="ms-3 mb-4">Most contemporary PCBs come with documentation or computer-aided design (CAD) data for people to review. Sometimes, PCBs don’t have any information available for them. In this case, reverse engineering is crucial to gather the necessary schematic and design information on the PCB. Reverse engineering allows you to create your own documentation on the product, helping you understand how to repair or reproduce it.</p>
                  </div>
                  <div className="container   ">
                        <h3 className="team-job__single">Reduce your reliance on experts:</h3>
                        <p className="ms-3 mb-4">One of the first steps you’ll probably take when your PCB is having an issue is to contact an expert for help with troubleshooting and repairs. Sometimes, there aren’t any professionals who have the expertise to handle your PCB. This lack of knowledge about the PCB can put you in a bind. Reverse engineering allows you to receive crucial information about your PCB and spot areas that could be causing a problem without the help of a professional.</p>
                  </div>
                  <div className="container   ">
                        <h3 className="team-job__single">Identify weaknesses in a competitor’s designs:</h3>
                        <p className="ms-3 mb-4">If you produce PCBs, you may want to find ways to make your products more effective than your competitors’ offerings. By reverse engineering a competitor’s PCB, you can identify weaknesses in it. With that information, you can improve your own PCBs and sell a more effective product. Even if you’re not trying to sell a PCB, you can identify weaknesses in a PCB’s design to build more effective ones for your unique purposes.</p>
                  </div>
                  <div className="container   ">
                        <h3 className="team-job__single">Produce less costly PCBs:</h3>
                        <p className="ms-3 mb-4">If you have a competitor selling a PCB at a high price, you can reverse engineer it to determine how much it actually costs to produce. Companies will often sell a PCB at a higher cost due to a lack of competition. By determining how much it costs to make the PCB, you could produce a similar one at a more cost-effective price, helping you attract new customers who previously didn’t have any other options.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          
          
          {/* Fun fact */}
          {/* <Funfact /> */}

          {/* Team member */}
          {/* <TeamMemberGrid /> */}

          {/* Testimonial Slider */}
          {/* <TestimonialSlider /> */}

          {/* Pcbdesignslider */}
          <Pcbdesignslider />

          
        </div>

        {/* Service Tab */}
        <Calltoactionfour/>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Reverseservice;
