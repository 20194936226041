import React, { Component } from 'react';

class Pcbaddservice extends Component{
    render(){

        let data = [
            { jobDesc: "ICT: Develop test fixtures and testing with our key partners.",},
            { jobDesc: "Conformal coat.",},
            // { jobDesc: "Large & small volume management of Electronic Manufacturing Service (EMS) at low costs",},
            // { jobDesc: "Generic Electronic Design & Consulting Services",}
            // {jobTitle: "Production Weekender", jobDesc: "here are many variations of passages", jobLink: "/"}
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="team-job__single" key={i}>
                    {/* <h3 className="title"> <a href={val.jobLink}>{val.jobTitle}</a></h3> */}
                    <p className="text">{val.jobDesc}</p>
                </div>
            )
        });
        return(
            <div>
                {/*====================  team job area ====================*/}
                <div className="team-job-area section-space--bottom--60">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-12">
                        <div className="team-job__content">
                        <div className="team-job__title-wrapper">
                            <h2 className="team-job__title mb-0">Other Value-Add Services.</h2>
                            {/* <button onClick={null} className="see-more-link see-more-link--color">Meet Our Team</button> */}
                        </div>
                        <div className="team-job__content-wrapper">
                            {/* <h2 className="team-job__title">Open Job <span><button onClick={null} className="see-more-link see-more-link--color">VIEW ALL JOBS</button></span></h2> */}
                            <div className="team-job__list-wrapper">
                                {DataList}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of team job area  ====================*/}

            </div>
        )
    }
}

export default Pcbaddservice;