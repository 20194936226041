import React, { Component } from "react";
import {Link} from 'react-router-dom';
import NavBar from "../components/NavBar";
import FeatureIcon from "../components/FeatureIcon";
import Enclosurslider from "../components/Enclosurslider";
import TeamMemberGrid from "../components/TeamMemberGrid";
import TestimonialSlider from "../components/TestimonialSlider";
import ComponentBrand from "../components/ComponentBrand";
import Pcbassemblyjob from "../components/Pcbassemblyjob";
import Pcbinsertion from "../components/Pcbinsertion";
import Pcbaddservice from "../components/Pcbaddservice";
import Pcbnote from "../components/Pcbnote";
import Calltoactionseven from '../components/Calltoactionseven';
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ModalVideo from "react-modal-video";
class Enclosuredesign extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    let data = [
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "SMT Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-2.jpg",
        projectTitle: "BGA Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "Through-Hole Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "Mixed Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-3.jpg",
        projectTitle: "Rigid Flex PCB Assembly Services",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                  <img
                    src={`assets/img/projects/${val.img}`}
                    className="img-fluid w-100"
                    alt=""
                  />
              </div>
            </div>
            <div className="service-grid-item__content p-3">
              <h3 className="title m-0">
                  {val.projectTitle}
              </h3>
              {/* <a
                href={`${process.env.PUBLIC_URL}/${val.pageLink}`}
                className="see-more-link"
              >
                SEE MORE
              </a> */}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                <h1>Enclosure Design</h1>
                  {/* <ul className="page-breadcrumb">
                    <li>
                    <Link to = {`${process.env.PUBLIC_URL}/home-two`}>Home</Link>
                    </li>
                    <li>Enclosure Design</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--0 pt-4">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                
                
              <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>3D Printed <br></br>Electronics Enclosure</h3>
                    <p>
                    Our experienced mechanical team has experience in manufacturing enclosures that have a great fit, finish, and function while staying within budget.
                    </p>
                    {/* <a
                      href="services"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Learn More
                    </a> */}
                  </div>
                </div>
                
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/about/en1.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two mb-5">
                    <h3>On-Demand 3d Printing Services for All Your Manufacturing Needs</h3>
                    <p>We are offering on-demand 3d printing assistance. We offer modern manufacturing solutions to help your business gain a competitive edge in this Industry 4.0 revolution. Experience on-demand services backed with cutting-edge technology to grow your business seamlessly. We strive to deliver you quick service to reduce the overall time-to-market and let you gain an edge in your industry.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
              <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Use of Additive Manufacturing Technologies Across Industries.</h3>
                    <p>
                    As 3D printing price continues to come down and become more affordable, businesses across various industries are now able to tap into the limitless potential of 3D printing technologies. The applications for manufacturing 3D print are virtually limitless.
                    </p>
                    <p>
                    We offers top-of-the-line 3D printing services, including rapid prototyping and spare parts management, at surprisingly affordable prices, to the following industries in India.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="about-image-two">
                    {/* <img src="assets/img/about/about-3.jpg" alt="" /> */}
                    <img src="assets/img/projects/en2.jpg" alt=""></img>
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          {/*About section end*/}

          

          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>3D Printing Technologies We Offer.</h3>
                    <p>
                    We offer: FDM 3D Printing:
                    </p>
                    <p>
                    In FDM technology, the component is made by fused depositing melted plastic as per the 3d CAD file. The FDM process uses thermoplastic polymers and is ideal for cost-effective small batch production. Some commonly used materials for the FDM process are PLA, ABS, and Nylon 6. If you plan to use additive manufacturing for rapid prototyping, FDM is ideal for you.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="about-image-two">
                    {/* <img src="assets/img/about/about-3.jpg" alt="" /> */}
                    <img src="assets/img/projects/en3.jpg" alt=""></img>
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

           {/*About section start*/}
           <div className="about-section section-space--inner--bottom--0">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two mb-5">
                    <p>We offer you the most detailed 3d printing services in India to meet all your design and manufacturing requirements. With years of experience in digital manufacturing, we understand your business needs and help you gain a competitive edge by offering:</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two mb-5">
                    <h3>Faster Turnaround Time:</h3>
                    <p>We are the fastest 3d printing service providers. We offer our customers robust quality in the shortest time to reduce their time to market.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
              <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="about-content-two">
                    <p class="team-job__single">
                    3D Printing Type: FDM Printing
                    </p>
                    <p class="team-job__single">
                    FDM 3D printing with PLA, ABS, and PETG material
                    </p>
                    <p class="team-job__single">
                    Multicolour printing, you can choose from different colours
                    </p>
                    <p class="team-job__single">
                    Rapid prototyping & full-scale production
                    </p>
                    <p class="team-job__single">
                    IP Protection to ensure your designs are Safe
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="about-image-two">
                    {/* <img src="assets/img/about/about-3.jpg" alt="" /> */}
                    <img src="assets/img/projects/innn.jpeg" alt=""></img>
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          {/*About section end*/}

          

          {/* Enclosurslider */}
          <Enclosurslider />

          {/* Team member */}
          {/* <TeamMemberGrid /> */}

          {/* Testimonial Slider */}
          {/* <TestimonialSlider /> */}

          
        </div>

        {/* Service Tab */}
        <Calltoactionseven/>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Enclosuredesign;
