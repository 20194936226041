import React, {Component} from 'react';

class ServiceGrid extends Component{
    render(){
        let data = [
            {img:'1.jpg', title: 'PCB Design', postExcerpt: 'Our team consists of experienced engineers, no matter how complicated the project is, our engineers can design the schematic diagram to meet your requirements.', link:'service-details-left-sidebar'},
            {img:'2.jpg', title: 'PCB fabrication & assistance', postExcerpt: 'We are dealing with manufacturing assistance for quick turn Prototypes & Bulk Quantity manufacturing for Single, Multilayer, Metal Clad PCBs.', link:'service-details-left-sidebar'},
            {img:'3.jpg', title: 'PCB assembly', postExcerpt: 'We are dealing with SMD and THT assembly services as partial and full turn-key with PCBA Testing, and continuous monitoring of quality till completion.', link:'service-details-left-sidebar'},
            {img:'1.jpg', title: 'Component sourcing', postExcerpt: 'We provide you with the best electronic component prices after analyzing various Trusted electronic component distributors.', link:'service-details-left-sidebar'},
            {img:'2.jpg', title: 'Turnkey product development', postExcerpt: 'Our production unit is fully capable of manufacturing and delivering products in any number and volume, with short lead times.', link:'service-details-left-sidebar'},
            {img:'3.jpg', title: 'Reverse Engineering services', postExcerpt: 'We have deep experience in PCB reverse Engineering for bulk production, which includes both reverses with/without dismantling the layers and components.', link:'service-details-left-sidebar'},
            {img:'1.jpg', title: 'Prototype design', postExcerpt: 'We have a well-defined process for designing and developing high-performance, mixed-signal hardware with rich software interfaces. We use different PCB design software to meet your needs which provides high-level PCB design files with 3D CAD models.', link:'service-details-left-sidebar'},
            {img:'2.jpg', title: 'Inductor design assistance', postExcerpt: 'We are assisting all types of Ferrite Coils, SMPS Transformers, Current Transformers, Indoor Lighting Products, Industrial Lighting Products, Street Lighting Products, Flood Lighting Products & wide range of electrical products in power-saving products.', link:'service-details-left-sidebar'},
            {img:'3.jpg', title: 'Enclosure Design', postExcerpt: 'Our experienced mechanical team has experience in designing enclosures that have a great fit, finish, and function while staying within budget.', link:'service-details-left-sidebar'}
            // {img:'3.jpg', date:'AUGUST 6, 2019', title: 'Lorem ipsum dolor sit amet.', postExcerpt: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Saepe minus, illo error ratione eos ex.…', link:'blog-details-left-sidebar'}
      
        ];

        let DataList = data.map((val, i) => {
            return(
                <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
                    <div className="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
                        <div className="blog-post-slider__image section-space--bottom--30">
                            <a href={`${process.env.PUBLIC_URL}/${val.link}`}><img src={`assets/img/blog/${val.img}`} className="img-fluid" alt="" /></a>
                            </div>
                            <div className="blog-post-slider__content">
                            {/* <p className="post-date"> {val.date}</p> */}
                            <h3 className="post-title mt-30">
                                <a href={`${process.env.PUBLIC_URL}/${val.link}`}>{val.title}</a>
                            </h3> 
                            <p className="post-excerpt">{val.postExcerpt}</p>
                            {/* <a href={`${process.env.PUBLIC_URL}/${val.link}`} className="see-more-link">SEE MORE</a> */}
                        </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                {/*====================  blog grid area ====================*/}
                <div className={`blog-grid-area section-space--inner--60 ${this.props.background}`}>
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                <h2 className="section-title section-space--bottom--50">New Blog <span className="title-icon" /></h2>
                                </div>
                            </div> */}
                            <div className="col-lg-12">
                                <div className="blog-grid-wrapper">
                                <div className="row">
                                    {DataList}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of blog grid area  ====================*/}

            </div>
        )
    }
}


export default ServiceGrid;