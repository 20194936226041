import React, { Component } from "react";
import {Link} from 'react-router-dom';
import NavBar from "../components/NavBar";
import FeatureIcon from "../components/FeatureIcon";
import Pcbdesignslider from "../components/Pcbdesignslider";
import TeamMemberGrid from "../components/TeamMemberGrid";
import TestimonialSlider from "../components/TestimonialSlider";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Calltoactionone from '../components/Calltoactionone';
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ModalVideo from "react-modal-video";
class Pcbdesign extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>PCB Design</h1>
                  {/* <ul className="page-breadcrumb">
                    <li>
                    <Link to = {`${process.env.PUBLIC_URL}/home-two`}>Home</Link>
                    </li>
                    <li>PCB Design</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--0 pt-4">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-3">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h1>Multi-Layer PCB Design Service</h1>
                    <p>
                      Our team consists of experienced engineers, no matter how
                      complicated the project is, our engineers can design the
                      schematic diagram to meet your requirements.
                    </p>
                    <p>
                      7+ years of experience in offering Custom PCB Design
                      Services for Single, Multi and multi-layer PCB layout and
                      analysis for complex multi-processor and multi-layer
                      boards with proven PCB Layout Design services
                      methodologies, process and quality checks. Our engineers
                      prioritize performance, manufacturability, cost, and
                      design for testability to ensure the best possible Design.
                    </p>
                    {/* <a
                      href="services"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Learn More
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/about/1pcb.png" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}


          <div className="about-section section-space--inner--bottom--0 pb-3">
            <div className="container">
              <div className="row row-25 align-items-center">
                
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-content-two">
                    <h3>
                      PCB designers at Hallmark Pcb Services have the experience
                      to bring your boards to life.
                    </h3>
                    <p>
                      Whether you’re developing a new product or reverse
                      engineering an existing one, we also ways perform at the
                      best level.
                    </p>
                    <h1>We are ideal for:</h1>
                    <p>
                      PCB board designers and electrical engineers building
                      prototypes or bringing products to market Contract
                      manufacturers who need fast, predictable lead times
                      Purchasing teams who require pricing and supply-chain
                      transparency Tinkerers, hobbyists, and builders who would
                      benefit from the expertise of our in-house CAM engineers.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-image-two">
                    <img src="assets/img/about/2pcb.png" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="about-section section-space--inner--bottom--0 pb-3">
            <div class="container">
              <div class="row row-25 align-items-center">
                <div class="col-12 mb-3">
                  <div class="about-content-two mb-3">
                    <h3>Printed Circuit Board Design Services, Capabilities, and Testing</h3>
                  </div>
                  <div class="container">
                    <h3 class="team-job__single mb-3">
                    Schematic capture
                    </h3>
                    <p class="ms-3  mb-2">We can create schematics from specifications, marked-up schematic sheets, or black diagrams and verbal discussions. We accept schematics in any format (PDF, sketch, etc.) and can convert that into the preferred CAD format. As part of our process, we validate the BOM.</p>
                  </div>
                  <div class="container">
                    <h3 class="team-job__single  mb-3">Reverse engineering</h3>
                    <p class="ms-3  mb-2">Are you missing your original PCB design data and documentation?  We can take an existing board from a bare board, an assembled board, and existing drawings, and we can create a new design with all supporting documentation.</p>
                  </div>
                  <div class="container">
                    <h3 class="team-job__single mb-3">
                    Cost optimization
                    </h3>
                    <p class="ms-3  mb-2">We can review an existing product or design and propose methods for cost reduction. In many cases, layer reduction can be achieved by using smaller geometries for conductors and vias.</p>
                  </div>
                  <div class="container">
                    <h3 class="team-job__single mb-3">
                    PCB design tools
                    </h3>
                    <p class="ms-3  mb-2">We use the standard tools from Cadence, Siemens, and Altium. We can accept a netlist or a partial / fully placed netlist. We can use our library of parts or customer libraries. We can provide a 24/7 service and our design team can schedule video conferences to support you. We have access to off-shore resources that can work on your design while you sleep.</p>
                  </div>
                  <div class="container row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <h3 class="team-job__single mb-3">
                      Additional Experience
                      </h3>
                      <p class="ms-3 mb-2">1. EMI protection</p>
                      <p class="ms-3 mb-2">2. High-speed signal design</p>
                      <p class="ms-3 mb-2">3. Differential impedance control</p>
                      <p class="ms-3 mb-2">4. Fine pitch BGA layout</p>
                      <p class="ms-3 mb-2">5. High current/voltage designs</p>
                      <p class="ms-3 mb-2">6. Sensitive analog circuits</p>
                      <p class="ms-3 mb-2">7. Design for manufacturability</p>
                      <p class="ms-3 mb-2">8. Design for testability</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <h3 class="team-job__single mb-3">
                      Deliverables
                      </h3>
                      <p class="ms-3 mb-2">1. Complete Altium Project File</p>
                      <p class="ms-3 mb-2">2. DFM (Design for Manufacturing) Checks</p>
                      <p class="ms-3 mb-2">3. Fabrication Files</p>
                      <p class="ms-3 mb-2">4. Panelized Data</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="about-section section-space--inner--bottom--0 ">
            <div class="container">
              <div class="row row-25 align-items-center">
                <div class="col-12 mb-3">
                  <div class="about-content-two mb-3">
                    <h3>PCB Design Includes</h3>
                  </div>
                  <div class="container row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <h3 class="team-job__single mb-3">
                      Board Design:
                      </h3>
                      <p class="ms-3 mb-2">1. System & Board Level Architecture Specification</p>
                      <p class="ms-3 mb-2">2. Engineering Design Specification</p>
                      <p class="ms-3 mb-2">3. Component Selection</p>
                      <p class="ms-3 mb-2">4. Component Obsolescence & Planning</p>
                      <p class="ms-3 mb-2">5. Symbol Creation</p>
                      <p class="ms-3 mb-2">6. Schematic Drawing/Capture</p>
                      <p class="ms-3 mb-2">7. Timing Analysis</p>
                      <p class="ms-3 mb-2">8. Netlist Generation</p>
                      <p class="ms-3 mb-2">9. BOM Generation</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <h3 class="team-job__single mb-3">
                      PCB Layout:
                      </h3>
                      <p class="ms-3 mb-2">1. Footprint Creation</p>
                      <p class="ms-3 mb-2">2. Placement</p>
                      <p class="ms-3 mb-2">3. Routing</p>
                      <p class="ms-3 mb-2">4. Power DC Drop Simulation</p>
                      <p class="ms-3 mb-2">5. Power Integrity and Signal Integrity Simulation</p>
                      <p class="ms-3 mb-2">6. Gerber Generation</p>
                      <p class="ms-3 mb-2">7. Tools: (Allegro, Altium, PADs, Expedition)</p>
                      <p class="ms-3 mb-2">8. Services</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Pcbdesignslider */}
          <Pcbdesignslider />

          {/* Team member */}
          {/* <TeamMemberGrid /> */}

          {/* Testimonial Slider */}
          {/* <TestimonialSlider /> */}

          {/* Brand logo */}
          {/* <BrandLogoSlider background="" /> */}
        </div>

        {/* Service Tab */}
        <Calltoactionone/>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Pcbdesign;
