import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Swiper from 'react-id-swiper';

class HeroSliderOne extends Component{
    
    render(){
        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 1000,
            watchSlidesVisibility: true,
            effect: 'fade',
            navigation: {
                nextEl: '.ht-swiper-button-next',
                prevEl: '.ht-swiper-button-prev'
            },
            renderPrevButton: () => (
                <div className="ht-swiper-button-prev ht-swiper-button-nav d-none d-xl-block"><i className="ion-ios-arrow-left" /></div>
              ),
              renderNextButton: () => (
                <div className="ht-swiper-button-next ht-swiper-button-nav d-none d-xl-block"><i className="ion-ios-arrow-forward" /></div>
              ),
            autoplay: {
                delay: 7000,
            }
        }

        let data = [
            {bgImg: 'slider11.gif', sliderTitle: 'One Stop Solution for Turnkey Product Development', sliderSubtitle: '(Design, Fabrication, Component Sourcing, Assembly, Testing and Enclosure Design)', btnLink: 'home-two',  img:'s111.png'},
            {bgImg: 's8.png', sliderTitletwo: 'Turnkey Product Development', sliderSubtitletwo: 'Our production unit is fully capable of manufacturing and delivering products in any number and volume, with short lead times.', btnLink: 'Turnkeyproduct', img:'s88.png'},
            {bgImg: 's4.png', sliderTitletwo: 'PCB Fabrication & Assistance', sliderSubtitletwo: 'We are dealing with manufacturing assistance for quick turn Prototypes & Bulk Quantity manufacturing for Single Multilayer, Metal Clad PCBs.', btnLink: 'Pcbfabrication', img:'s444.png'},
            {bgImg: 's5.png', sliderTitletwo: 'PCB Design Service', sliderSubtitletwo: 'Our team consists of experienced engineers, no matter how complicated the project is, our engineers can design the schematic diagram to meet your requirements.', btnLink: 'pcbdesign', img:'s555.png'},
            {bgImg: 's2.png', sliderTitletwo: 'Inductor Design Assistance', sliderSubtitletwo: 'We are providing assistance of all types of Ferrite Coils, HV Coils, EMI Filter Coils, Flood Lights & wide range of electrical products in power saving solutions since last three decades.', btnLink: 'Inductordesign', img:'s222.png'},
            {bgImg: 's6.png', sliderTitletwo: 'PCB Assembly', sliderSubtitletwo: 'We are dealing with SMD and THT assembly services as partial and full turn-key with PCBA Testing, and continuous monitoring of quality till final completion.', btnLink: 'Pcbassembly', img:'s666.png'},
            {bgImg: 's7.png', sliderTitle: '3D Printed Electronics Enclosure', sliderSubtitle: 'Our experienced mechanical team has experience in manufacturing enclosures that have a great fit, finish, and function while staying within budget.', btnLink: 'Enclosuredesign', img:'s777.png'},
            {bgImg: 's3.png', sliderTitletwo: 'Global Parts Sourcing Available Now', sliderSubtitletwo: 'We provide you the best electronic component prices after analyzing from various different trusted electronic component distributors.', btnLink: 'Componentsourcing', img:'s333.png'},
            {bgImg: 's1.png', sliderTitletwo: 'PCB Reverse Engineering', sliderSubtitletwo: 'Reverse Engineering Are you interested in PCB Reverse Engineering ?', btnLink: 'Reverseservice', img:'5.png'},
        ];

        let DataList = data.map((val, i)=>{
            return(
                
                <div className="swiper-slide" key={i}>
                    <div className="hero-slider__single-item" style={{ backgroundImage: `url(assets/img/slider/${val.bgImg})` }} >
                        <div className="hero-slider__content-wrapper">
                            <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                <div className="hero-slider__content">
                                    <h2 className="text-light hero-slider__title mb-0">{val.sliderTitle}</h2>
                                    <h2 className="text-dark hero-slider__title">{val.sliderTitletwo}</h2>
                                    <p className="text-light hero-slider__text mb-2">{val.sliderSubtitle}</p>
                                    <p className="text-dark hero-slider__text">{val.sliderSubtitletwo}</p>
                                    <Link className="ht-btn ht-btn--default d-inline-block text-light fw-bold hero-slider__btn" style={{backgroundColor:'#009933', border:'none'}}  to={`${process.env.PUBLIC_URL}/${val.btnLink}`}>GET START</Link>
                                    {/* <a className="text-light fw-bold hero-slider__btn" style={{backgroundColor:'#009933', border:'none'}} href={`${process.env.PUBLIC_URL}/${val.btnLink}`}> GET START</a> */}
                                </div>
                                {/* style={{color:'#0093cb'}} */}
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                <div className="mb-4 hero-slider_con">
                                    <img src={`assets/img/slider/${val.img}`} className="img-fluid" alt="" />
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                {/*====================  hero slider area ====================*/}
                <div className="hero-alider-area">
                    <Swiper {...params}>
                        {DataList}
                    </Swiper>
                </div>
                {/*====================  End of hero slider area  ====================*/}

            </div>
        )
    }
}

export default HeroSliderOne;