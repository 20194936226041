import React, { Component } from "react";
import {Link} from 'react-router-dom';
import NavBar from "../components/NavBar";
import FeatureIcon from "../components/FeatureIcon";
import Iconsourcing from "../components/Iconsourcing";
import TeamMemberGrid from "../components/TeamMemberGrid";
import TestimonialSlider from "../components/TestimonialSlider";
import ComponentBrand from "../components/ComponentBrand";
import Pcbassemblyjob from "../components/Pcbassemblyjob";
import Pcbinsertion from "../components/Pcbinsertion";
import Pcbaddservice from "../components/Pcbaddservice";
import Pcbnote from "../components/Pcbnote";
import Calltoactionfive from '../components/Calltoactionfive';
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ModalVideo from "react-modal-video";
class Componentsourcing extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    let data = [
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "SMT Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-2.jpg",
        projectTitle: "BGA Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "Through-Hole Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "Mixed Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-3.jpg",
        projectTitle: "Rigid Flex PCB Assembly Services",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                  <img
                    src={`assets/img/projects/${val.img}`}
                    className="img-fluid w-100"
                    alt=""
                  />
              </div>
            </div>
            <div className="service-grid-item__content p-3">
              <h3 className="title m-0">
                  {val.projectTitle}
              </h3>
              {/* <a
                href={`${process.env.PUBLIC_URL}/${val.pageLink}`}
                className="see-more-link"
              >
                SEE MORE
              </a> */}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Component Sourcing</h1>
                  {/* <ul className="page-breadcrumb">
                    <li>
                    <Link to = {`${process.env.PUBLIC_URL}/home-two`}>Home</Link>
                    </li>
                    <li>Component Sourcing</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--0 pt-5">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                
                
                
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Global Parts Sourcing <br></br>Available Now</h3>
                    <p>
                    We provide you the best electronic component prices after analyzing from various different trusted electronic component distributors.
                    </p>
                    {/* <a
                      href="services"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Learn More
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/about/cust1.jpeg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-3">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two">
                    <p>With over 7+ years’ experience in electronics manufacturing, we know how to utilise our procurement capabilities to best suit our customers’ unique project needs. Our team will collaborate closely with our customers. We can take full management of the procurement process, work to specific requirements, or work with components free-issued by our customers themselves. Our engineers and buyers will meticulously review your bill of materials (BOM) at the beginning of the purchasing process.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-3">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two">
                    <h3>What we do.</h3>
                    <p>We work with many different businesses to source and supply electronic components which include hard to find and obsolete parts. With quality and price guarantees in place. You can rest assured that with Hallmark distribution services you will get the best deal delivered with a personal touch.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two">
                    <h3>How we make it happen</h3>
                    <p>It just takes 5 simple steps.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          
          {/* Fun fact */}
          <Iconsourcing/>

          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-3 pt-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Save Money, Save Time and Improve Productivity!</h3>
                    <p>We give you the convenience of placing one large order, up to twelve months in advance. All the benefits that come with this include Price breaks, lower unit costs that are fixed for a year.</p>
                    <p>You also get piece of mind knowing that your stock is held in a dedicated facility and is allocated only for your use.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-3">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Right Stock. Right Place. Right Time. Right Price.</h3>
                    <p>We work on your annual forecasts. Once we have discussed your predicted requirements for the years, we get to work. Thanks to our global procurement network and vetted database of suppliers, we are able to lock down large quantities of available stock on your behalf – no matter where those components are located.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          
          {/* Fun fact */}
          {/* <Funfact /> */}

          {/* Team member */}
          {/* <TeamMemberGrid /> */}

          {/* Testimonial Slider */}
          {/* <TestimonialSlider /> */}
          {/* Brand logo */}
          <ComponentBrand background="" />
          
        </div>

        {/* Service Tab */}
        <Calltoactionfive/>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Componentsourcing;
