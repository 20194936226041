import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

class Funfact extends Component {
  state = {
    didViewCountUp: false
  };

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };

  render() {
    let data = [
      { img: "Year Solid Experience.png", countNum: 7, countTitle: "Year Solid Experience" },
      { img: "Happy Customers.png", countNum: 50, countTitle: " Happy Customers" },
      { img: "Complex Designs Done.png", countNum: 150, countTitle: "Complex Designs Done" },
      { img: "PCB Traces Routed.png", countNum: 100000, countTitle: "PCB Traces Routed" },
      { img: "BOM Executed.png", countNum: 30, countTitle: "BOM Executed" }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div
          className="single-fact col-lg-2 col-md-6 col-sm-12 section-space--bottom--30"
          key={i}
        >
          <img src={`assets/img/icons/${val.img}`} alt="" height={60} width={60} />
          <h1 className="counter">
            <VisibilitySensor
              onChange={this.onVisibilityChange}
              offset={{ top: 10 }}
              delayedCall
            >
              <CountUp end={this.state.didViewCountUp ? val.countNum : 0} />
            </VisibilitySensor>+
          </h1>
          <h4>{val.countTitle}</h4>
        </div>
      );
    });

    return (
      <div>
        {/*====================  fun fact area ====================*/}
        <div className="container section-space--top--60">
          <h2 className="section-title section-space--bottom-- text-center w-100">Build Robust & Reliable Electronics Products <span className="title-icon" /></h2>
        </div>
        <div
          className="funfact-section section-space--inner--100 funfact-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="fun-fact-wrapper">
                  <div className="row justify-content-between">{DataList}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of fun fact area  ====================*/}
      </div>
    );
  }
}

export default Funfact;
