import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

class Referencetbl extends Component {
  state = {
    didViewCountUp: false
  };

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };

  render() {
    let data = [
      { img: "whyuss.jpg", countTitleone:"WHY US?"},
      { img: "ON-TIME DELIVERY.png",  countTitle: "ON-TIME DELIVERY", countTitlep: "With Dedicated transport facility we are ensuring on time delivary."},
      { img: "EXPERIENCE STAFF.png",  countTitle: "EXPERIENCE STAFF", countTitlep: "24x7 Available with most professional consultance and services."},
      { img: "QUALITY ASSURANCE.png",  countTitle: "QUALITY ASSURANCE", countTitlep: "During the design and menufacturing, entire process will go through stringent tests & coutinuous monitoring."},
      { img: "CREATIVE & PROFESSIONAL TEAM.png",  countTitle: "CREATIVE & PROFESSIONAL TEAM", countTitlep: "We are developing your product professionaly with creativity of quality till completion."},
      { img: "CUSTOMIZATION SOLUTIONS.png",  countTitle: "CUSTOMIZATION SOLUTIONS", countTitlep: "Customize the solution according to customer expectation."}
    ];

    let DataList = data.map((val, i) => {
      return (
        <div
          className="single-fact col-lg-4 col-md-4 col-sm-12 p-3 border bg-white  d-flex align-items-center justify-content-center" style={{flexDirection: 'column' }}
          key={i}
        >
          <img src={`assets/img/icons/${val.img}`} alt="" height={60} width={60}/>
          <h1 className="mb-0">
          {val.countTitleone}
          </h1>
          <h4 className="text-dark">{val.countTitle}</h4>
          <p className="pt-3">{val.countTitlep}</p>
        </div>
      );
    });

    return (
      <div>
        {/*====================  fun fact area ====================*/}
        <div className=" section-space--inner--80 pt-5" style={{backgroundImage: `url("assets/img/backgrounds/funfact-bg.jpg")`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="fun-fact-wrapper">
                  <div className="row justify-content-between">{DataList}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of fun fact area  ====================*/}
      </div>
    );
  }
}

export default Referencetbl;
