import React, { Component } from "react";
import {Link} from 'react-router-dom';
import NavBar from "../components/NavBar";
import FeatureIcon from "../components/FeatureIcon";
import Overallindustries from "../components/Overallindustries";
import TeamMemberGrid from "../components/TeamMemberGrid";
import TestimonialSlider from "../components/TestimonialSlider";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Calltoactioneight from '../components/Calltoactioneight';
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ModalVideo from "react-modal-video";
class Inductordesign extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    let data = [
      {
        // pageLink: "project-details",
        img: "in2.jpg",
        projectTitle: "Single Layer PCB",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "in3.jpg",
        projectTitle: "Multi-Layer PCB",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "in4.jpg",
        projectTitle: "Metal Core PCB",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                <img
                  src={`assets/img/projects/${val.img}`}
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </div>
            {/* <div className="service-grid-item__content p-3">
              <h3 className="title m-0">
                  {val.projectTitle}
              </h3>
              <a
                href={`${process.env.PUBLIC_URL}/${val.pageLink}`}
                className="see-more-link"
              >
                SEE MORE
              </a>
            </div> */}
          </div>
        </div>
      );
    });
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Inductor Design Assistance</h1>
                  {/* <ul className="page-breadcrumb">
                    <li>
                    <Link to = {`${process.env.PUBLIC_URL}/home-two`}>Home</Link>
                    </li>
                    <li>Inductor Design Assistance</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--0 pt-4">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Inductor Design Assistance</h3>
                    <p>
                      We are providing assistance of all types of Ferrite Coils,
                      SMPS Transformers, HV Coils, EMI Filter Coils and
                      Solutions, Indoor Lighting Products, Industrial Lighting
                      Products, Flood Lights & wide range of electrical products
                      in power saving solutions since last three decades.
                    </p>
                    {/* <a
                      href="services"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Learn More
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/about/induct.jpg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/*====================  project page content ====================*/}
          <div className="page-wrapper section-space--inner--0 ">
            {/*Projects section start*/}
            <div className="project-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="project-item-wrapper">
                      <div className="row">{Datalist}</div>
                    </div>
                  </div>
                </div>
                {/* <div className="row section-space--top--60">
                <div className="col">
                  <ul className="page-pagination">
                    <li>
                      <a href="/">
                        <i className="fa fa-angle-left" /> Prev
                      </a>
                    </li>
                    <li className="active">
                      <a href="/">01</a>
                    </li>
                    <li>
                      <a href="/">02</a>
                    </li>
                    <li>
                      <a href="/">03</a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-angle-right" /> Next
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
              </div>
            </div>
            {/*Projects section end*/}
          </div>

          {/* <div>
            <div class="team-job-area section-space--inner--60">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-12">
                    <div class="team-job__content">
                      <div class="team-job__title-wrapper pb-3 mb-4">
                        <h2 class="team-job__title mb-0">
                        Overall Industries Catered:
                        </h2>
                      </div>
                      <div class="team-job__content-wrapper">
                        <div class="team-job__list-wrapper">
                          <div class="team-job__single mb-3">
                            <p class="text">
                            Electronics Industry
                            </p>
                          </div>
                          <div class="team-job__single mb-3">
                            <p class="text">
                            Health care Industry
                            </p>
                          </div>
                          <div class="team-job__single mb-3">
                            <p class="text">
                            Telecommunication industry
                            </p>
                          </div>
                          <div class="team-job__single mb-3">
                            <p class="text">
                            Mining Industry
                            </p>
                          </div>
                          <div class="team-job__single mb-3">
                            <p class="text">
                            Food Industry
                            </p>
                          </div>
                          <div class="team-job__single mb-3">
                            <p class="text">
                            Computer Power Supply Industry
                            </p>
                          </div>
                          <div class="team-job__single mb-3">
                            <p class="text">
                            Energy Industry
                            </p>
                          </div>
                          <div class="team-job__single mb-3">
                            <p class="text">
                            Pharmaceutical Industry
                            </p>
                          </div>
                          <div class="team-job__single mb-3">
                            <p class="text">
                            Aerospace Industry
                            </p>
                          </div>
                          <div class="team-job__single mb-3">
                            <p class="text">
                            Hospitality Industry
                            </p>
                          </div>
                          <div class="team-job__single mb-3">
                            <p class="text">
                            Agriculture Equipment industry
                            </p>
                          </div>
                          <div class="team-job__single mb-3">
                            <p class="text">
                            All Electrical & Electronics Apparatus
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="about-section section-space--inner--bottom--0 pb-5">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-12 col-12 mb-30">
                  <div className="about-content-two">
                    <p>
                    We are providing PCB Manufacturing assistance for Single layer, Multi-layer plated through holes, and Metal Core PCB, etc.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/*====================  End of project page content  ====================*/}
          

          {/* Fun fact */}
          <Overallindustries />

          {/* Team member */}
          {/* <TeamMemberGrid /> */}

          {/* Testimonial Slider */}
          {/* <TestimonialSlider /> */}

          {/* Brand logo */}
          {/* <BrandLogoSlider background="" /> */}
        </div>

        {/* Service Tab */}
        <Calltoactioneight/>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Inductordesign;
