import React, { Component } from 'react';

class FeatureIcon extends Component{
    render(){

        let data = [
            {featureIcon: "Release your request in 1 minute.png", featureTitle: "Release your request in 1 minute", featureDescription: "Intelligent recommendation of quality Service providers."},
            {featureIcon: "Communication and Confirmation With service providers.png", featureTitle: "Communication and Confirmation With service providers", featureDescription: "Full transaction and intellectual property protection."},
            {featureIcon: "Project implementation and acceptance.png", featureTitle: "Project implementation and acceptance", featureDescription: "Platform supervision, excellent after-sales service."}
        ];

        let Datalist = data.map((val, i)=>{
            return(
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="feature">
                    <div className="icon">
                        <img src={`assets/img/icons/${val.featureIcon}`} className="img-fluid" alt="" height={60} width={60} />
                    </div>
                    <div className="content">
                        <h3>{val.featureTitle}</h3>
                        <p>{val.featureDescription}</p>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                {/*Feature section start*/}
                <div className={`feature-section section-space--inner--50 pt-50 mb-50 grey-bg`}>
                    <div className="container">
                        <h2 className="section-title section-space--bottom--30 w-100 text-center">Send your request now<span className="title-icon" /></h2>
                        <div className="col-lg-12">
                            <div className="feature-item-wrapper">
                                <div className="row">
                                    {Datalist}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Feature section end*/}
            </div>
        )
    }
}

export default FeatureIcon;