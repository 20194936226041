import React, { Component } from "react";
import {Link} from 'react-router-dom';
import NavBar from "../components/NavBar";
import FeatureIcon from "../components/FeatureIcon";
import AboutFunfact from "../components/AboutFunfact";
import Whyusbox from "../components/Whyusbox";
import TeamMemberGrid from "../components/TeamMemberGrid";
import TestimonialSlider from "../components/TestimonialSlider";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ModalVideo from "react-modal-video";
class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Why Us ?</h1>
                  {/* <ul className="page-breadcrumb">
                    <li>
                    <Link to = {`${process.env.PUBLIC_URL}/home-two`}>Home</Link>
                    </li>
                    <li>Why Us ?</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--50">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0">
            <div className="container">
              <div className="row row-25 align-items-center">
                {/* <div className="col-lg-6 col-12 mb-30">
                  <div className="about-image-two">
                    <img src="assets/img/about/about-3.jpg" alt="" />
                    <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span>
                  </div>
                </div> */}
                <div className="col-lg-3 col-12 mb-30 why3" id="">
                  <div className="about-content-two row">
                    <div className="col-lg-12 col-md-6 col-12 my-3">
                      <div className="image-hover  border whyborder rounded text-center p-3 h-100">
                        <div className="image-cont">
                          <img src="assets/img/icons/8+ Years of Experience.png" alt="" height={60} width={60} />
                          <p class="mb-0 py-3 video-cta-content__text w-100">7+ Years of Experience</p>
                          <p className="text">With Our experience and expertise, Our Customer will get perfect PCB Solution from Us.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 my-3">
                      <div className="image-hover border  whyborder rounded text-center p-3 h-100">
                        <div className="image-cont">
                          <img src="assets/img/icons/PCB Solutions.png" alt="" height={60} width={60} />
                          <p class="mb-0 py-3 video-cta-content__text w-100">PCB Solutions</p>
                          <p className="text">At Company, Customer will get PCB Designing to PCB manufacturing Solution at One Place.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mb-30 text-center why2" id="">
                  <div className="about-content-two">
                    <h1>Most Efficient, Economic and Innovative Electronics Service Provider.</h1>
                    <p>
                      Our experience in product development and manufacturing provides an end-to-end turnkey solution for companies seeking full product development services from a single source.
                    </p>
                    <p>With close to 7+ years of solid experience in Electronics Design, we have completed many high-tech & complex Electronic Product Designs. We believe in creating values by providing innovative and unparalleled services and solutions in the field of Electronic Manufacturing, Design & Engineering, Rapid Prototyping and New Product Introduction. We always strive to deliver high-quality services at affordable costs.
                    </p>
                    <p>
                    We have been recognized for our fast turnaround with “First-Time Right Design” techniques giving you faster time-to-market. The products that we have designed are field proven & working successfully 24x7 over several years, Our Designs are always exceeding customer expectations.
                    </p>
                    {/* <a
                      href="services"
                      className="ht-btn--default ht-btn--default--dark-hover"
                    >
                      Our Services
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-3 col-12 mb-30 why1" id="">
                  <div className="about-content-two row">
                    <div className="col-lg-12 col-md-6 my-3">
                      <div className="image-hover  border  whyborder rounded text-center p-3 h-100">
                        <div className="image-cont">
                          <img src="assets/img/icons/On Call Service Support.png" alt="" height={60} width={60} />
                          <p class="mb-0 py-3 video-cta-content__text w-100">On Call Service Support</p>
                          <p className="text">We offering 24X7 service to full fill customer need and for fast service availability.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 my-3">
                      <div className="image-hover  border whyborder rounded text-center p-3 h-100">
                        <div className="image-cont">
                          <img src="assets/img/icons/Assurance Quality.png" alt=""  height={60} width={60} />
                          <p class="mb-0 py-3 video-cta-content__text w-100">Quality Assurance</p>
                          <p className="text">We assure that our offered PCB Products that full fill international quality standards.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          <div className="about-section section-space--inner--bottom--0">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-30">
                  <div className="about-content-two row justify-content-center">
                    <div className="col-lg-3 col-md-6 col-12 my-3">
                      <div className="image-hover  border  whyborder rounded text-center p-3 h-100">
                        <div className="image-cont">
                          <img src="assets/img/icons/ON-TIME DELIVERY.png" alt="" height={60} width={60} />
                          <p class="mb-0 py-3 video-cta-content__text w-100">On-Time Delivery</p>
                          <p className="text">Intelligent recommendation of quality Service providers.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 my-3">
                      <div className="image-hover  border whyborder rounded text-center p-3 h-100">
                        <div className="image-cont">
                          <img src="assets/img/icons/Affordable Price.png" alt="" height={60} width={60} />
                          <p class="mb-0 py-3 video-cta-content__text w-100">Affordable Price</p>
                          <p className="text">Our vision is that our customers get World Class PCB at affordable price.</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 col-12 my-3">
                      <div className="image-hover  border  whyborder rounded text-center p-3 h-100">
                        <div className="image-cont">
                          <img src="assets/img/icons/Assurance Quality.png" alt="" height={60} width={60} />
                          <p class="mb-0 py-3 video-cta-content__text w-100">Assurance Quality</p>
                          <p className="text">We assure that we products with international quality standards.</p>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-lg-3 col-md-6 col-12 my-3">
                      <div className="image-hover  border whyborder rounded text-center p-3 h-100">
                        <div className="image-cont">
                          <img src="assets/img/icons/Data protection.png" alt="" height={60} width={60} />
                          <p class="mb-0 py-3 video-cta-content__text w-100">Data protection</p>
                          <p className="text">We provide data security to our clients with NDA for better services.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Feature Icon */}
          {/* <FeatureIcon background="grey-bg" /> */}

          {/*About section start*/}
          {/* <div className="about-section section-space--inner--60">
            <div className="container">
              <div className="about-wrapper row">
                <div className="col-sm-6 col-12 order-1 order-lg-2">
                  <div className="about-image about-image-1">
                    <img src="assets/img/about/about-1.jpg" alt="" />
                  </div>
                </div>
                <div className="col-sm-6 col-12 order-2 order-lg-3">
                  <div className="about-image about-image-2">
                    <img src="assets/img/about/about-2.jpg" alt="" />
                  </div>
                </div>
                <div className="col-lg-6 col-12 order-3 order-lg-1">
                  <div className="about-content about-content-1">
                    <h1>
                      <span>High Quality</span>
                    </h1>
                    <p>
                      ligendi optio cumque nihil impedit quo minus id quod
                      maxime placeat facere possimus, omnis voluptas assumenda
                      est, omnis dolor ellendus. Temporibus autem quibusdam et
                      aut officiis debitis aut rerum atibus saepe eveniet ut et
                      voluptates repudiandae sint et molestiae
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 order-4">
                  <div className="about-content about-content-2">
                    <h1>
                      <span>Lower Cost</span>
                    </h1>
                    <p>
                      ligendi optio cumque nihil impedit quo minus id quod
                      maxime placeat facere possimus, omnis voluptas assumenda
                      est, omnis dolor ellendus. Temporibus autem quibusdam et
                      aut officiis debitis aut rerum atibus saepe eveniet ut et
                      voluptates repudiandae sint et molestiae non recusandae.
                      Itaque earum rerum hic tenetur a sapie
                    </p>
                  </div>
                </div>
              </div>
              <div className="about-wrapper row">
                <div className="col-sm-6 col-12 order-1 order-lg-2">
                  <div className="about-image about-image-1">
                    <img src="assets/img/about/about-1.jpg" alt="" />
                  </div>
                </div>
                <div className="col-sm-6 col-12 order-2 order-lg-3">
                  <div className="about-image about-image-2">
                    <img src="assets/img/about/about-2.jpg" alt="" />
                  </div>
                </div>
                <div className="col-lg-6 col-12 order-3 order-lg-1">
                  <div className="about-content about-content-1">
                    <h1>
                      <span>Fast Delivery</span>
                    </h1>
                    <p>
                      ligendi optio cumque nihil impedit quo minus id quod
                      maxime placeat facere possimus, omnis voluptas assumenda
                      est, omnis dolor ellendus. Temporibus autem quibusdam et
                      aut officiis debitis aut rerum atibus saepe eveniet ut et
                      voluptates repudiandae sint et molestiae
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 order-4">
                  <div className="about-content about-content-2">
                    <h1>
                      <span>Quality Assurance</span>
                    </h1>
                    <p>
                      ligendi optio cumque nihil impedit quo minus id quod
                      maxime placeat facere possimus, omnis voluptas assumenda
                      est, omnis dolor ellendus. Temporibus autem quibusdam et
                      aut officiis debitis aut rerum atibus saepe eveniet ut et
                      voluptates repudiandae sint et molestiae non recusandae.
                      Itaque earum rerum hic tenetur a sapie
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/*About section end*/}

          {/* Fun fact */}
          {/* <Funfact /> */}

          {/* Team member */}
          {/* <TeamMemberGrid /> */}

          {/* Testimonial Slider */}
          {/* <TestimonialSlider /> */}

          {/* Brand logo */}
          {/* <BrandLogoSlider background="" /> */}
        </div>
        {/* <div className="page-wrapper section-space--bottom--50">
          <div className="about-section section-space--inner--bottom--0">
            <div className="container">
              <div className="row row-25 align-items-center justify-content-center">
                <div className="col-lg-6 col-12 mb-30 text-center">
                  <div className="about-content-two">
                  <img src="assets/img/icons/Assurance Quality.png" alt="" height={60} width={60} />
                    <h1 className="py-3 mb-0">Quality Assurance</h1>
                    <p>         
                        Quality Assurance During the Design and Manufacturing entire process will go through stringent tests and continuous monitoring of quality till completion.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Fun fact */}
        <AboutFunfact/>
        
        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default About;
