import React, { Component } from "react";

class Pcbmanu extends Component {
  render() {
  

    return (
      <div>
        {/*====================  blog grid area ====================*/}
        <div class="about-section section-space--inner--bottom--0 pt-4">
          <div class="container">
            <div class="row row-25 align-items-center">
              <div class="col-12 mt-3">
                <div class="about-content-two">
                  <div class="">
                    <h3 class="text text-center">
                      We have in house PCB manufacturing facility for quick turn
                      Prototype & Bulk Quantity manufacturing for Single &
                      Double Sided PCBs.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-section section-space--inner--bottom--0 pb-4 pt-4">
          <div class="container">
            <div
              class="row row-25 align-items-center"
              style={{ overflowX: "scroll" }}
            >
              <div class="col-12 mb-3 mt-3">
                <table class="table table-bordered border-primary">
                  <thead className="text-light" style={{backgroundColor:'#009933'}}>
                    <tr>
                      <th scope="col">SR No.</th>
                      <th scope="col">Technical Specification</th>
                      <th scope="col">Single Side</th>
                      <th scope="col">Double Side</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Lead time</td>
                      <td>
                        24 hour’s, 3 days, 5 days, 7 days, 10 days, 15 days,
                        21days
                      </td>
                      <td>
                        24 hour’s, 3 days, 5 days, 7 days, 10 days, 15 days,
                        21days
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Material</td>
                      <td>
                        FR1,FR4, CEM1, CEM3, Paper Phenolic, Metal Clad(Al)
                      </td>
                      <td>FR4</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>PCB Thickness (mm)</td>
                      <td>0.4, 0.8, 1.0, 1.2, 1.6, 2.0, 2.4, 3.2</td>
                      <td>0.4, 0.8, 1.0, 1.2, 1.6, 2.0, 2.4, 3.2</td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>Finish Copper Thickness</td>
                      <td>1 oz (35 µ), 2 oz (70 µ), 3 oz(105 µ)</td>
                      <td>1 oz (35 µ), 2 oz (70 µ), 3 oz(105 µ)</td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>Maximum Board size</td>
                      <td>400 X 500 mm </td>
                      <td>400 X 500 mm </td>
                    </tr>
                    <tr>
                      <th scope="row">6</th>
                      <td>Minimum Track Width</td>
                      <td>4mil(0.1 mm)</td>
                      <td>4mil(0.1 mm)</td>
                    </tr>
                    <tr>
                      <th scope="row">7</th>
                      <td>Minimum Finish Hole size</td>
                      <td>10mil(0.25mm) </td>
                      <td>10mil(0.25mm) </td>
                    </tr>
                    <tr>
                      <th scope="row">8</th>
                      <td>Maximum Finish PTH Hole size</td>
                      <td>240 mil(6 mm) </td>
                      <td>240 mil(6 mm) </td>
                    </tr>
                    <tr>
                      <th scope="row">9</th>
                      <td>Cutting Option</td>
                      <td>Routing , V groove , Punching</td>
                      <td>Routing , V groove , Punching</td>
                    </tr>
                    <tr>
                      <th scope="row">10</th>
                      <td>Electrical Testing</td>
                      <td>BBT, FPT </td>
                      <td>BBT, FPT </td>
                    </tr>
                    <tr>
                      <th scope="row">11</th>
                      <td>Mask colour</td>
                      <td>No mask, Green, Red, White, Black, Blue</td>
                      <td>No mask, Green, Red, White, Black, Blue</td>
                    </tr>
                    <tr>
                      <th scope="row">12</th>
                      <td>Legend Colour</td>
                      <td>White, Black, No legend</td>
                      <td>White, Black, No legend</td>
                    </tr>
                    <tr>
                      <th scope="row">13</th>
                      <td>Surface Finish</td>
                      <td>HAL, ENIG, OSP, Lacquer, Nickel</td>
                      <td>HAL, ENIG, OSP, Lacquer, Nickel</td>
                    </tr>
                    <tr>
                      <th scope="row">14</th>
                      <td>Special Requirement</td>
                      <td>
                        Carbon Printing on Push Button , Selective Gold & Nickel
                        plating on Connecting Tabs
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <th scope="row">15</th>
                      <td>ROHS Compliance</td>
                      <td>Yes</td>
                      <td>Yes</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="about-section section-space--inner--bottom--0 pb-4 pt-4">
          <div class="container">
            <div class="row row-25 align-items-center">
              <div class="col-12 mb-3">
                <div class="about-content-two">
                  <h3 className="mb-4">Critical Specification</h3>
                  <div class="team-job__single">
                    <p class="text">
                      We require data in Gerber 274 X format and Drill file in
                      Excellon Format. (If Possible then avoid to send data in
                      274 d format)
                    </p>
                  </div>
                  <div class="team-job__single">
                    <p class="text">
                      If data is in 247 d format, then make sure that aperture
                      file is there.
                    </p>
                  </div>
                  <div class="team-job__content-wrapper">
                    <div class="team-job__list-wrapper">
                      <div class="pl-3 mb-3 ">
                        <p class="text">
                          For Double sided PCB we require following
                          files/details in Gerber data
                        </p>
                      </div>
                      <div class="pl-5 mb-3">
                        <p class="">= 2 Track layer (Top and Bottom)</p>
                      </div>
                      <div class="pl-5 mb-3">
                        <p class="">= 2 Mask layer (Top and Bottom)</p>
                      </div>
                      <div class="pl-5 mb-4">
                        <p class="">= Legend Print (Top and Bottom)</p>
                      </div>
                    </div>
                  </div>
                  <div class="team-job__content-wrapper mt-5">
                    <div class="team-job__list-wrapper">
                      <div class="pl-3 mb-3">
                        <p class="text">
                          For Single sided PCB we require following
                          files/details in Gerber data
                        </p>
                      </div>
                      <div class="pl-5 mb-3">
                        <p class="">= 1 Track layer (Top or Bottom)</p>
                      </div>
                      <div class="pl-5 mb-3">
                        <p class="">
                          = 1 Mask layer (Top or Bottom according track layer)
                        </p>
                      </div>
                      <div class="pl-5 mb-3">
                        <p class="">= Legend Print (Top or Bottom, both)</p>
                      </div>
                    </div>
                  </div>
                  <div class="team-job__single mt-5">
                    <p class="text">
                      Use easy to understand named layer. It should be easy to
                      determine layer name or readme file for identification.
                    </p>
                  </div>
                  <div class="team-job__single">
                    <p class="text">
                      Do not mirror (or reflect) any data layer – image or
                      drill. Viewing a PCB from top to bottom through the board
                      is the universal practice in the PCB industry. This means
                      that when viewing your Gerber data, text on the top side
                      of your board (copper, solder mask, legend) should be
                      readable and text on the bottom side (copper, solder mask,
                      legend) should be nonreadable (= mirrored).
                    </p>
                  </div>
                  <div class="team-job__single">
                    <p class="text">
                      = Drill drawing (PTH, NPTH and Slot size to be define)
                    </p>
                  </div>
                  <div class="team-job__single">
                    <p class="text">
                      = Mechanical / Routing layer /Profile layer
                    </p>
                  </div>
                  <div class="team-job__single">
                    <p class="text">= NC drill file</p>
                  </div>
                  <div class="team-job__single">
                    <p class="text">
                      = Any special layers (Gold Tab, Carbon Layer, Peel Off,
                      Paste layers)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pcbmanu;
