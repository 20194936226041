import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

class Iconsourcing extends Component {
  state = {
    didViewCountUp: false
  };

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };

  render() {
    let data = [
      { img: "Upload BOM.png",  countTitle: "Upload BOM" },
      { img: "BOM Processing.png",  countTitle: "BOM Processing" },
      { img: "Collecting Prices.png", countTitle: "Collecting Prices" },
      { img: "Selecting best price.png",  countTitle: "Selecting best price" },
      { img: "Report Generation.png",  countTitle: "Report Generation" }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div
          className="singlee-fact col-lg-2 col-md-6 col-sm-12 section-space--bottom--30"
          key={i}
        >
          <img src={`assets/img/icons/${val.img}`} alt="" height={60} width={60}/>
          <h4>{val.countTitle}</h4>
        </div>
      );
    });

    return (
      <div>
        {/*====================  fun fact area ====================*/}
        <div
          className="funfact-sectionn pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="fun-fact-wrapper">
                  <div className="row justify-content-between">{DataList}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of fun fact area  ====================*/}
      </div>
    );
  }
}

export default Iconsourcing;
