import React, {Component} from 'react';
import Swiper from 'react-id-swiper';

class ValuedCustomer extends Component{
    render(){

        const params = {
            slidesPerView : 4,
            loop: true,
            speed: 1000,
            spaceBetween : 30,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false
            },
           
            // Responsive breakpoints
            breakpoints: {
                1499:{
                    slidesPerView : 4
                },

                991:{
                    slidesPerView : 3
                },

                767:{
                    slidesPerView : 3

                },

                575:{
                    slidesPerView : 2
                }
            }
        }

        let data = [
            {img: '1.png', logoLink: '/'},
            {img: '2.png', logoLink: '/'},
            {img: '3.png', logoLink: '/'},
            {img: '4.png', logoLink: '/'},
            {img: '1.png', logoLink: '/'},
            {img: '2.png', logoLink: '/'},
            {img: '3.png', logoLink: '/'},
            // {img: '1.png', logotext: 'Sunrom Electronics', logoLink: '/'},

        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="swiper-slide brand-logo-slider__single" key={i}>
                    <div className="image text-center">
                        <a className="mb-4" href={val.logoLink}>
                            <img src={`assets/img/brand-logo/${val.img}`} className="img-fluid" alt="" />
                        </a>
                        {/* <p className="pt-10 fs-bold">{val.logotext}</p> */}
                    </div>
                </div>
            )
        });


        return(
            <div>
                {/*====================  brand logo area ====================*/}
                <div className={`brand-logo-slider-area pt-4 pb-4 section-space--bottom--60 ${this.props.background}`}>
                <div className="container">
                <h2 className="section-title section-space--bottom--30 w-100 text-center">Our Valued Customers<span className="title-icon" /></h2>
                    <div className="row">
                    <div className="col-lg-12">
                        {/* brand logo slider */}
                        <div className="brand-logo-slider__container-area">
                            <Swiper {...params}>
                                {DataList}
                            </Swiper>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of brand logo area  ====================*/}
            </div>
        )
    }
}

export default ValuedCustomer;