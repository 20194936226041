import React, { Component } from "react";
import Swiper from "react-id-swiper";

class TestimonialSlider extends Component {
  render() {
    const params = {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      effect: "fade",
      autoplay: {
        delay: 3000
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
      },
      renderPagenation: () => <div className="swiper-pagination"></div>
    };
    let data = [
      {
        testimonialImage: "funfact-clients.png",
        testimonialName: "Mr.Krishanudas",
        testimonialDesignation: "(Piot Technology.llp)",
        testimonialText:
          "“In my history of working with trade show vendors, I can honestly say that there is not one company that I've ever worked with that has better service than Exhibit Systems.”"
      },
      {
        testimonialImage: "funfact-clients.png",
        testimonialName: "Mr. Chirag Pandya",
        testimonialDesignation: "(Amazing Automation)",
        testimonialText:
          "“One of the best electronics and automation-based solution providers in Bhavnagar. Such a creative & knowledgeable team. Had a great experience with you, guys. Timely delivered a lot of 5k PCBs of my product.”"
      },
      {
        testimonialImage: "funfact-clients.png",
        testimonialName: "Mr. Deepak Bhimani",
        testimonialDesignation: "(Distributer)",
        testimonialText:
          "“Whenever you are working on electrical circuits, it is highly important to ensure that your circuits and PCB are fully functional, I have seen every solution by Hallmark PCB service and Fully satisfaction.... really great work.”"
      },
      {
        testimonialImage: "funfact-clients.png",
        testimonialName: "Mr. Rushil Oza",
        testimonialDesignation: "(Engineer)",
        testimonialText:
          "“Totally satisfied with service. Team with great knowledge and gives best solution for your project.”"
      },
      {
        testimonialImage: "funfact-clients.png",
        testimonialName: "Mr. Chetan Chauhan",
        testimonialDesignation: "(PCB Design Engineer) – Electro Tech CC",
        testimonialText:
          "“Awesome PCB printing quality...!! Received in two days...!!”"
      },
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className="swiper-slide testimonial-slider__single-slide" key={i}>
          <div className="author">
            <div className="author__image">
              <img
                src={`assets/img/testimonial/${val.testimonialImage}`}
                alt="" height={100} width={100}
              />
            </div>
            <div className="author__details">
              <h4 className="name text-dark">{val.testimonialName}</h4>
              <div className="designation text-dark">{val.testimonialDesignation}</div>
            </div>
          </div>
          <div className="content text-dark">{val.testimonialText}</div>
        </div>
      );
    });

    return (
      <div>
        {/*====================  testimonial slider area ====================*/}
        <div
          className="testimonial-slider-area testimonial-slider-area-bg section-space--inner--50 section-space--bottom--"
          style={{
            backgroundImage: `url(assets/img/backgrounds/.jpg)`
          }}
        >
          <div className="container">
          <h2 className="section-title section-space--bottom--30 w-100 text-center">What our clients say about us<span className="title-icon" /></h2>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="testimonial-slider">
                  <div className="testimonial-slider__container-area">
                    <Swiper {...params}>{DataList}</Swiper>
                    {/* <div className="swiper-pagination border"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of testimonial slider area  ====================*/}
      </div>
    );
  }
}

export default TestimonialSlider;
