import React, { Component } from 'react';

class Pcbassemblyjob extends Component{
    render(){

        let data = [
            { jobDesc: "Expertise in single, multi-layer flexible and rigid circuitry boards",},
            { jobDesc: "Thorough design review before starting the assembly",},
            { jobDesc: "Consignment or full turnkey solution",},
            { jobDesc: "In-depth knowledge of various application requirements",},
            { jobDesc: "Proficient in surface mounting, through-hole, and mixed technology",},
            { jobDesc: "Prompt response to design changes, requirement updates, and questions",},
            // { jobDesc: "Large & small volume management of Electronic Manufacturing Service (EMS) at low costs",},
            // { jobDesc: "Generic Electronic Design & Consulting Services",}
            // {jobTitle: "Production Weekender", jobDesc: "here are many variations of passages", jobLink: "/"}
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="team-job__single" key={i}>
                    {/* <h3 className="title"> <a href={val.jobLink}>{val.jobTitle}</a></h3> */}
                    <p className="text">{val.jobDesc}</p>
                </div>
            )
        });
        return(
            <div>
                {/*====================  team job area ====================*/}
                <div className="team-job-area section-space--inner--60">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-12">
                        <div className="team-job__content">
                        <div className="team-job__title-wrapper">
                            <h2 className="team-job__title mb-0">Assembly Capabilities.</h2>
                            {/* <button onClick={null} className="see-more-link see-more-link--color">Meet Our Team</button> */}
                        </div>
                        <div className="team-job__content-wrapper">
                            {/* <h2 className="team-job__title">Open Job <span><button onClick={null} className="see-more-link see-more-link--color">VIEW ALL JOBS</button></span></h2> */}
                            <div className="team-job__list-wrapper">
                                {DataList}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of team job area  ====================*/}

            </div>
        )
    }
}

export default Pcbassemblyjob;