import React, { Component } from "react";
import {Link} from 'react-router-dom';
import NavBar from "../components/NavBar";
import FeatureIcon from "../components/FeatureIcon";
import Funfact from "../components/Funfact";
import TeamMemberGrid from "../components/TeamMemberGrid";
import TestimonialSlider from "../components/TestimonialSlider";
import ComponentBrand from "../components/ComponentBrand";
import Pcbassemblyjob from "../components/Pcbassemblyjob";
import Pcbinsertion from "../components/Pcbinsertion";
import Pcbaddservice from "../components/Pcbaddservice";
import Pcbnote from "../components/Pcbnote";
import Calltoactionsix from '../components/Calltoactionsix';
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ModalVideo from "react-modal-video";
class Turnkeyproduct extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    let data = [
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "SMT Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-2.jpg",
        projectTitle: "BGA Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "Through-Hole Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-1.jpg",
        projectTitle: "Mixed Assembly",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        // pageLink: "project-details",
        img: "project-3.jpg",
        projectTitle: "Rigid Flex PCB Assembly Services",
        // projectSubtitle:
        //   "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                  <img
                    src={`assets/img/projects/${val.img}`}
                    className="img-fluid w-100"
                    alt=""
                  />
              </div>
            </div>
            <div className="service-grid-item__content p-3">
              <h3 className="title m-0">
                  {val.projectTitle}
              </h3>
              {/* <a
                href={`${process.env.PUBLIC_URL}/${val.pageLink}`}
                className="see-more-link"
              >
                SEE MORE
              </a> */}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                <h1>Turnkey Product Development</h1>
                  {/* <ul className="page-breadcrumb">
                    <li>
                    <Link to = {`${process.env.PUBLIC_URL}/home-two`}>Home</Link>
                    </li>
                    <li>Turnkey Product Development</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--0 pt-4">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                
                
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Turnkey Product Development <br></br>Prototype Design</h3>
                    <p>
                    Our production unit is fully capable of manufacturing and delivering products in any number and volume, with short lead times.
                    </p>
                    {/* <a
                      href="services"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Learn More
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/about/tur1.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>Order full turnkey PCBs, entirely Manufactured, fully assembled.</h3>
                    <p>
                    We provide Full turnkey service with integrated suppliers for transparent pricing. Get an integrated quote for PCB fabrication, components, and assembly.
                    </p>
                    <div>
                    <h3 className="team-job__single mb-3">PCB fabrication</h3>
                    </div>
                    <p>
                    Your standard technology PCBs are fabricated to the highest quality.
                    </p> 
                    <div>
                    <h3 className="team-job__single mb-3">Component procurement</h3>
                    </div>
                    <p>
                    Turnkey provides fast and efficient pricing with no hidden costs!
                    </p> 
                    <div>
                    <h3 className="team-job__single mb-3">Assembly</h3>
                    </div>
                    <p>
                    We create an unbroken thread from design to delivery. Our engineers and turnkey PCB build teams are always accessible and responsive from quote through production and shipping.
                    </p> 
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/about/tur2.jpg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-12 mb-3">
                  <div className="about-content-two mb-4">
                    <h3>PRODUCT PROMISE</h3>
                    <p>Rapid PCB prototyping service: PCBs manufactured and assembled as quickly as you need them. Hallmark PCB Services can provide full turnkey boards in as fast as 24 hours!</p>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-12 ">
                          <h3 className="team-job__single">Create your boards entirely online:</h3>
                          <p className=" mb-2">PCB fabrication</p>
                          <p className=" mb-2">Component procurement</p>
                          <p className=" mb-2">In-house assembly</p>
                    </div>
                    <div className="col-lg-4 col-12 ">
                          <h3 className="team-job__single ">Assembly Specs:</h3>
                          <p className=" mb-2">Dedicated CAM engineer</p>
                          <p className=" mb-2">BOM check</p>
                          <p className=" mb-2">DFA check</p>
                          <p className=" mb-2">DFM check</p>
                          <p className=" mb-2">100% net-list testing</p>
                    </div>
                    <div className="col-lg-4 col-12 ">
                          <h3 className="team-job__single">Prototype More Effectively</h3>
                          <p className=" mb-2">1 – 100 fully assembled boards</p>
                          <p className=" mb-2">Standard FR-4 material with ENIG or HASL finish</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--0 pb-4">
            <div className="container">
              <div className="row row-25 align-items-center">
                
                <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="about-content-two">
                    <h3>High-quality, high-performance PCBs. Fully assembled. Made to order. Ready to ship in as quick as 24 hours.</h3>
                    <p>
                    Whether you’re an engineer on a tight deadline or a procurement officer looking to simplify your supply chain, Turnkey is the most dependable full-service turnkey PCB solution.
                    </p>
                    <div>
                    <h3 className="team-job__single mb-3">IDEAL FOR:</h3>
                    </div>
                    <p>
                    PCB designers and electrical engineers building prototypes or bringing products to market.
                    </p>
                    <p>
                    Contract manufacturers who need fast, predictable lead times.
                    </p>
                    <p>
                    Purchasing teams who require pricing and supply-chain transparency.
                    </p> 
                    <p>
                    Tinkerers, hobbyists, and builders who would benefit from the expertise of our in-house CAM engineers.
                    </p>
                    <div>
                    <h3 className="team-job__single mb-3">FULL TURNKEY ALSO INCLUDES:</h3>
                    </div>
                    <p>
                    PCB fabrication
                    </p>
                    <p>
                    Component procurement
                    </p> 
                    <p>
                    In-house assembly
                    </p> 
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="about-image-two">
                    <img src="assets/img/about/tur3.jpg" alt="" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}
          
          {/* Fun fact */}
          {/* <Funfact /> */}

          {/* Team member */}
          {/* <TeamMemberGrid /> */}

          {/* Testimonial Slider */}
          {/* <TestimonialSlider /> */}

          
        </div>

        {/* Service Tab */}
        <Calltoactionsix/>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Turnkeyproduct;
