import React, { Component } from "react";
import {Link} from 'react-router-dom';
import NavBar from "../components/NavBar";
import Pcbmanu from '../components/Pcbmanu';
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class Pcbmanufacturing extends Component {
  render() {

    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>PCB Manufacturing</h1>
                  {/* <ul className="page-breadcrumb">
                    <li>
                    <Link to = {`${process.env.PUBLIC_URL}/home-two`}>Home</Link>
                    </li>
                    <li>PCB Manufacturing</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/* Blog grid */}
        <Pcbmanu  background = "" />

        {/* Service Tab */}
        {/* <Calltoaction/> */}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Pcbmanufacturing;
