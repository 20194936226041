import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class MobileMenu extends Component{

    state = {
        active : false
    }

    toggleMobileMenu = () => {
        this.setState({
            active: !this.state.active
        })
    }

    componentDidMount(){
        var offCanvasNav = document.getElementById('offcanvas-navigation');
        var offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');

        for (let i = 0; i<offCanvasNavSubMenu.length; i++){
           offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
        }

        var menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
        var numMenuExpand = menuExpand.length;

        function sideMenuExpand() {

            if(this.parentElement.classList.contains('active') === true) {
                this.parentElement.classList.remove('active');
            } else {
                for (let i = 0; i < numMenuExpand; i++) {
                    menuExpand[i].parentElement.classList.remove('active');
                }
                this.parentElement.classList.add('active');
            }
        }

        for (let i = 0; i < numMenuExpand; i++) {
            menuExpand[i].addEventListener("click", sideMenuExpand);
        }
    }


    render(){
        return(
            <div>
                {/*=======  offcanvas mobile menu  =======*/}
                <div className= {`offcanvas-mobile-menu ${this.state.active ? 'active': ''} `} id="mobile-menu-overlay">
                    <a href="#/" className="offcanvas-menu-close" id="mobile-menu-close-trigger" onClick={this.toggleMobileMenu}>
                        <i className="ion-android-close" />
                    </a>
                    <div className="offcanvas-wrapper">
                        <div className="offcanvas-inner-content">
                            {/* <div className="offcanvas-mobile-search-area">
                                <form action="#">
                                <input type="search" placeholder="Search ..." />
                                <button type="submit"><i className="fa fa-search" /></button>
                                </form>
                            </div> */}
                            <nav className="offcanvas-navigation" id="offcanvas-navigation">
                                <ul>
                                    <li className="menu-item-has-children">
                                    <Link to={`${process.env.PUBLIC_URL}/home-two`}> HOME </Link>
                                    </li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/about-us`}>WHY US ?</Link></li>
                                    <li className="menu-item-has-children">
                                    <Link>SERVICE</Link>
                                        <ul className="sub-menu">
                                        <li><Link to={`${process.env.PUBLIC_URL}/pcbdesign`}>PCB design</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/pcbfabrication`}>PCB fabrication & assistance</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/pcbassembly`}>PCB assembly</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/reverseservice`}>Reverse Engineering services</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/componentsourcing`}>Component sourcing</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/turnkeyproduct`}>Turnkey product development</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/inductordesign`}>Inductor design assistance</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/enclosuredesign`}>Enclosure Design</Link></li>
                                                </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                    <Link>CAPABILITY</Link>
                                        <ul className="sub-menu">
                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>PCB Manufacturing</Link></li>
                                                {/* <li><Link to={`${process.env.PUBLIC_URL}/services`}>PCB Assembly</Link></li> */}
                                                </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                    <Link>RESOURCES</Link>
                                    <ul className="sub-menu">
                                                {/* <li  className="menu-item-has-children"><Link to={`${process.env.PUBLIC_URL}/services`}>Calculator (Digi-key)</Link>
                                                    <ul className="sub-menu">
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>Resistor Color Code Calculator</Link></li>
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>SMD Resistor Code Calculator</Link></li>
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>Capacitor Code Calculator</Link></li>
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>Capacitance Conversion</Link></li>
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>Inductance Conversion</Link></li>
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>Ohm’s Law Calculator</Link></li>
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>Voltage Divider Calculator</Link></li>
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>Current Divider Calculator</Link></li>
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>Series and Parallel Resistor Calculator</Link></li>
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>LED Series Resistor Calculator</Link></li>
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>PCB Trace Width Calculator</Link></li>
                                                        <li><Link to={`${process.env.PUBLIC_URL}/services`}>Trace Impedance Calculator</Link></li>
                                                    </ul>
                                                </li> */}
                                                <li><Link to={`${process.env.PUBLIC_URL}/faq`}>FAQ</Link></li>
                                                </ul>
                                    </li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/contact-us`}>CONTACT</Link> </li>
                                <div className="">
                                <div className="my-2">
                                    <Link className="ht-btn ht-btn--default d-inline-block" to={`${process.env.PUBLIC_URL}/requestquote`}>REQUEST QUOTE</Link>
                                    {/* <a href={`${process.env.PUBLIC_URL}/requestquote`} className="ht-btn ht-btn--default d-inline-block">REQUEST QUOTE</a> */}
                                </div>
                                <div className="my-2">
                                    <a href={`https://forms.gle/ByrKqz75V2xbErmS9`} className="ht-btn ht-btn--default d-inline-block">Call Back</a>
                                </div>
                            </div>
                                </ul>
                            </nav>
                            <div className="offcanvas-widget-area">
                                <div className="off-canvas-contact-widget">
                                <div className="header-contact-info">
                                    <ul className="header-contact-info__list">
                                    <li><i className="ion-android-phone-portrait" /> <a href="tel:8154 8181 88">(+91) 8154 8181 88</a></li>
                                    <li><i className="ion-android-mail" /> <a href="mailto:hallmarkpcb@gmail.com">hallmarkpcb@gmail.com</a></li>
                                    </ul>
                                </div>
                                </div>
                                {/*Off Canvas Widget Social Start*/}
                                <div className="off-canvas-widget-social">
                                <a href="https://www.facebook.com/people/HallMark-PCB-Services/100063872178328/"><i className="zmdi zmdi-facebook" /></a>
                                <a href="https://instagram.com/hallmarkpcbservices?igshid=YmMyMTA2M2Y="><i className="zmdi zmdi-instagram" /></a>
                                <a href="www.linkedin.com/in/hallmarkpcb"><i className="zmdi zmdi-linkedin-box" /></a>
                                <a href="https://wa.me/918154815402"><i className="zmdi zmdi-whatsapp" /></a>
                                </div>
                                {/*Off Canvas Widget Social End*/}
                            </div>
                        </div>
                    </div>
                </div>
                {/*=======  End of offcanvas mobile menu  =======*/}

            </div>
        )
    }
}

export default MobileMenu;