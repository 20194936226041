import React, { Component } from "react";
import {Link} from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? document.getElementById("scroll-top").classList.add("show")
      : document.getElementById("scroll-top").classList.remove("show");
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  footer area ====================*/}
        <div className="footer-area dark-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-content-wrapper section-space--inner--50 pt-50">
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-12">
                      {/* footer intro wrapper */}
                      <div className="footer-intro-wrapper">
                      <div className="footer-logo">
                          <Link to = {`${process.env.PUBLIC_URL}/home-two`}>
                              <img src="assets/img/logo/logo-light.png" className="img-fluid col-lg-12 col-sm-6 ps-0" alt="Logo" />
                          </Link>
                      </div>
                        {/* <div className="footer-logo">
                          <a href={`${process.env.PUBLIC_URL}/home-two`}>
                            <img
                              src="assets/img/logo/logo-light.png"
                              className="img-fluid col-lg-12 col-sm-6"
                              alt=""
                            />
                          </a>
                        </div> */}
                        <div className="footer-desc">
                        Our experience in product development and manufacturing provides an end-to-end turnkey solution for companies seeking full product development services from a single source. 
                        </div>
                        {/* With 
                        close to 5+ years of solid experience in 
                        Electronics Design, we have successfully 
                        completed many of high-tech & complex 
                        Electronic Product Designs. We always strive to 
                        deliver high quality services at affordable costs.
                        We are recognized for our fast turnaround with 
                        “First time right design” techniques giving you 
                        faster time-to-market.The products that we have 
                        designed are field proven & working successfully 
                        24/7 over several years, Our Designs are always 
                        exceeding customer expectations. */}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-10">
                      {/* footer intro wrapper */}
                      <h4 className="footer-widget__title">WE ARE AVAILABLE ON</h4>
                      <div className="footer-intro-wrapper">
                        <div className="footer-logo mb-0">
                          <a href="https://www.indiamart.com/hallmark-pcb-services/">
                            <img
                              src="assets/img/logo/Indiamart-new.jpeg"
                              className="p-0 img-fluid col-lg-12 col-sm-6"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="footer-logo">
                          {/* <a href={`${process.env.PUBLIC_URL}/home-one`}> */}
                            <img
                              src="assets/img/logo/made.jpeg"
                              className="pt-3 w-100 ps-0 pe-0 img-fluid col-lg-12 col-sm-6"
                              alt=""
                            />
                          {/* </a> */}
                        </div>
                        {/* With 
                        close to 5+ years of solid experience in 
                        Electronics Design, we have successfully 
                        completed many of high-tech & complex 
                        Electronic Product Designs. We always strive to 
                        deliver high quality services at affordable costs.
                        We are recognized for our fast turnaround with 
                        “First time right design” techniques giving you 
                        faster time-to-market.The products that we have 
                        designed are field proven & working successfully 
                        24/7 over several years, Our Designs are always 
                        exceeding customer expectations. */}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4   col-md-6">
                      {/* footer widget */}
                      <div className="footer-widget">
                        <h4 className="footer-widget__title">QUICK LINKS</h4>
                        <ul className="footer-widget__navigation">
                        <li><Link to={`${process.env.PUBLIC_URL}/pcbdesign`}>PCB Design</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/pcbfabrication`}>PCB Fabrication & Assistance</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/pcbassembly`}>PCB assembly</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/reverseservice`}>Reverse Engineering Services</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/componentsourcing`}>Component Sourcing</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/turnkeyproduct`}>Turnkey Product Development</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/inductordesign`}>Inductor Design Assistance</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/enclosuredesign`}>Enclosure Design</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/contact-us`}>Contact Us</Link></li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="col-xl-2  col-lg-3 col-md-4">
                      <div className="footer-widget">
                        <h4 className="footer-widget__title">QUICK LINKS</h4>
                        <ul className="footer-widget__navigation">
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/`}>PCB Layout</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/`}>PCB Fabrication</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/`}>PCB Assembly</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/`}>Component Sourcing</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/`}>Industries we serve</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/`}>FAQ’s</a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="col-xl-3 col-lg-4  col-md-6">
                      {/* footer widget */}
                      <div className="footer-widget mb-0">
                        <h4 className="footer-widget__title">GET IN TOUCH</h4>
                        <div className="footer-widget__content">
                          <p className="address">
                          Hallmark PCB Services 669, <br></br>Juna Gam, Vartej Road, Sidsar, Bhavnagar, Gujarat, INDIA - 364060
                          </p>
                          <ul className="contact-details footer-widget__navigation">
                            <li>
                              <span><img src="assets/img/icons/telephone.png" alt="" height="20" width="20"></img></span><a href="tel:8154 8181 88">(+91) 8154 8181 88</a>
                            </li>
                            <li className="mb-2">
                              <span><img src="assets/img/icons/gmail.png" alt="" height="20" width="20"></img></span><a href="mailto:hallmarkpcb@gmail.com">hallmarkpcb@gmail.com</a> <br></br>
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a href="mailto:sales@hallmarkpcb.com">sales@hallmarkpcb.com</a>
                            </li>
                            {/* <i className="fa-solid fa-envelope"></i> */}
                            <li>
                              <span><img src="assets/img/icons/calendar.png" alt="" height="20" width="20"></img></span>Business Hours : <br></br><br></br>
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Monday - Friday <br></br><br></br>
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>10:30 AM - 06:00 PM IST
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright-wrapper">
            <div className="container"> 
            <div className="footer-copyright row justify-content-between align-items-center">
              <p className="copyright-text mb-0 col-md-8 my-2">
                Copyright © 2021 - 2022 Hallmark PCB Services <i className="fa fa-heart"></i> All Right Reserved
              </p>
              <p className="copyright-text social-links col-md-4 my-2">
                Made by <i className="fa fa-heart text-danger"></i> <a href="https://mechodal.com/">Mechodal Technology</a>
              </p>
            </div>
            </div>
          </div>
        </div>
        {/*====================  End of footer area  ====================*/}
        {/*====================  scroll top ====================*/}
        <button
          className="scroll-top"
          id="scroll-top"
          onClick={this.scrollToTop}
        >
          <i className="ion-android-arrow-up" />
        </button>
        {/*====================  End of scroll top  ====================*/}
      </div>
    );
  }
}

export default Footer;
