import React, { Component } from "react";
import {Link} from 'react-router-dom';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class Contact extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Contact Us</h1>
                  {/* <ul className="page-breadcrumb">
                    <li>
                    <Link to = {`${process.env.PUBLIC_URL}/home-two`}>Home</Link>
                    </li>
                    <li>Contact Us</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  content page content ====================*/}
        <div className="page-wrapper section-space--inner--60">
          {/*Contact section start*/}
          <div className="conact-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-12 p-5">
                  <div className="contact-information">
                    <h3>Contact Information</h3>
                    <ul className="row">
                      <li className="col-lg-12">
                        <span className="icon">
                          <i className="ion-android-map" />
                        </span>
                        <span className="text">
                          <p className=" video-cta-content__text">Hallmark PCB Services 669, Juna Gam, Vartej Road, Sidsar, Bhavnagar, Gujarat, INDIA - 364060</p>
                        </span>
                      </li>
                      <li className="align-items-center col-lg-12">
                        <span className="icon">
                          <i className="ion-ios-telephone-outline" />
                        </span>
                        <span className="text">
                          <a href="tel:8154 8181 88" className=" video-cta-content__text mb-0">(+91) 8154 8181 88</a>
                          {/* <a href="tel:1234567890">(001) 65897 569 784</a> */}
                        </span>
                      </li>
                      <li className="col-lg-12">
                        <span className="icon">
                          <i className="ion-ios-email-outline" />
                        </span>
                        <span className="text">
                          <a href="mailto:hallmarkpcb@gmail.com" className=" video-cta-content__text mb-0">hallmarkpcb@gmail.com</a>
                          <a href="mailto:sales@hallmarkpcb.com" className=" video-cta-content__text">sales@hallmarkpcb.com</a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-12 p-5 whyborder rounded">
                  <div className="contact-form">
                    <h3>Get In Touch With Us</h3>
                    <p className="video-cta-content__text w-100">We are here to help you with all your queries and concerns. Just write to us using the below form and we will get back to you as soon as we can.</p>
                    <form id="contact-form">
                      <div className="row row-10">
                        <div className="col-lg-6 col-12 section-space--bottom--20">
                          <input
                            name="Name"
                            type="text"
                            placeholder="Name"
                          />
                        </div>
                        <div className="col-lg-6 col-12 section-space--bottom--20">
                          <input
                            name="Company_Name"
                            type="text"
                            placeholder="Company Name"
                          />
                        </div>
                        <div className="col-lg-6 col-12 section-space--bottom--20">
                          <input
                            name="Email"
                            type="email"
                            placeholder="Email"
                          />
                        </div>
                        <div className="col-lg-6 col-12 section-space--bottom--20">
                          <input
                            name="Mobile"
                            type="number"
                            placeholder="Mobile"
                          />
                        </div>
                        <div className="col-12">
                          <textarea
                            name="Message"
                            placeholder="Message"
                            defaultValue={""}
                          />
                        </div>
                        <div className="col-12">
                          <button>SUBMIT</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="row section-space--bottom--0 pt-5">
                <div className="col">
                  <div className="contact-map">
                    <iframe
                      title="google-map"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3706.163155519427!2d72.13428891494472!3d21.735199585620027!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f57c3ceb7311b%3A0x47ff4878ee20ba06!2sHallmark%20PCB%20Services!5e0!3m2!1sen!2sin!4v1662294774141!5m2!1sen!2sin"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Contact section end*/}
        </div>
        {/*====================  End of content page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Contact;
