import React, {Component} from 'react';
import Swiper from 'react-id-swiper';

class Industries extends Component{
    render(){

        const params = {
            slidesPerView : 4,
            loop: true,
            speed: 1000,
            spaceBetween : 30,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false
            },
           
            // Responsive breakpoints
            breakpoints: {
                1499:{
                    slidesPerView : 4
                },

                991:{
                    slidesPerView : 3
                },

                767:{
                    slidesPerView : 3

                },

                575:{
                    slidesPerView : 2
                }
            }
        }

        let data = [
            {img: 'Mining Industry.png', logotext: 'Mining Industry'},
            {img: 'Energy Industry.png', logotext: 'Energy Metering'},
            {img: 'Telecommunication industry.png', logotext: 'Communication'},
            {img: 'Computer Power Supply Industry.png', logotext: 'Power Supply'},
            {img: 'Food Industry.png', logotext: 'Food Industry'},
            {img: 'Electronics Industry.png', logotext: 'Electronics Industry'},
            {img: 'Health care Industry.png', logotext: 'Hospitality Industry'},
            {img: 'Agriculture Equipment industry.png', logotext: 'Agriculture Equipment industry'},
            {img: 'Aerospace Industry.png', logotext: 'Aerospace Industry'},
            {img: 'Pharmaceutical Industry.png', logotext: 'Pharmaceutical Industry'},
            {img: 'Electrical & Electronics.png', logotext: 'All Electrical & Electronics Apparatus'}
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="swiper-slide brand-logo-slider__single" key={i}>
                    <div className="image text-center">
                        <img src={`assets/img/brand-logo/${val.img}`} className="img-fluid mb-4 w-25" alt="" />
                        <p className="pt-10 fs-bold">{val.logotext}</p>
                    </div>
                </div>
            )
        });


        return(
            <div>
                {/*====================  brand logo area ====================*/}
                <div className={`brand-logo-slider-area section-space--inner--60  `}>
                <div className="container">
                <h2 className="section-title section-space--bottom--30 w-100 text-center">Overall Industries Catered:<span className="title-icon" /></h2>
                    <div className="row">
                    <div className="col-lg-12">
                        {/* brand logo slider */}
                        <div className="brand-logo-slider__container-area">
                            <Swiper {...params}>
                                {DataList}
                            </Swiper>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of brand logo area  ====================*/}
            </div>
        )
    }
}

export default Industries;