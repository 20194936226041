import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
class PageNotFound extends Component{
    render(){
        return(
            <div>
                {/* Navigation bar */}
                {/* <NavBar/> */}

                {/*====================  404 page content ====================*/}
                <div>
                    <div className="error-page-wrapper d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12 m-auto text-center">
                            <div className="error-content-centered d-flex align-items-center justfy-content-center">
                            <div className="error-page-content-wrap">
                                <h3 className='mb-3'>THANK YOU FOR PLACING QUOTATION REQUEST AT HALLMARK PCB SERVICES.</h3>
                                <p>We Will Revert You Back Soon.</p>
                                <p>You Can Also Call Us On <a href="tel:8154 8154 02">(+91) 8154 8154 02</a></p>
                                <p>( MON - FRI [10:00 AM To 06:00 PM] )</p>
                                <Link className="whyborder ht-btn ht-btn--default ht-btn--default--dark-hover" to={`${process.env.PUBLIC_URL}/home-two`}>Back to Home Page</Link>
                                {/* <a href={`${process.env.PUBLIC_URL}/home-two`} className="ht-btn ht-btn--default ht-btn--default--dark-hover">Back to Home Page</a> */}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                {/*====================  End of 404 page content  ====================*/}

                {/* Footer */}
                {/* <Footer/> */}

                {/* Mobile Menu */}
                {/* <MobileMenu/> */}

            </div>
        )
    }
}


export default PageNotFound;