import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
// import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
// import HomeThree from "./HomeThree";
import About from "./pages/About";
import Requestquote from "./service/Requestquote";
import Services from "./service/Services";
import Pcbdesign from "./service/Pcbdesign";
import Pcbfabrication from "./service/Pcbfabrication";
import Pcbassembly from "./service/Pcbassembly";
import Reverseservice from "./service/Reverseservice";
import Componentsourcing from "./service/Componentsourcing";
import Turnkeyproduct from "./service/Turnkeyproduct";
import Inductordesign from "./service/Inductordesign";
import Enclosuredesign from "./service/Enclosuredesign";
import Pcbmanufacturing from "./service/Pcbmanufacturing";
import ServiceDetailsLeftSidebar from "./service/ServiceDetailsLeftSidebar";
import ServiceDetailsRightSidebar from "./service/ServiceDetailsRightSidebar";
import Projects from "./project/Projects";
import ProjectDetails from "./project/ProjectDetails";
import BlogLeftSidebar from "./blog/BlogLeftSidebar";
import BlogRightSidebar from "./blog/BlogRightSidebar";
import BlogDetailsLeftSidebar from "./blog/BlogDetailsLeftSidebar";
import BlogDetailsRightSidebar from "./blog/BlogDetailsRightSidebar";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/ThankYou";
import NoMAtch from "./pages/ThankYou";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={HomeTwo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/home-two`}
            component={HomeTwo}
          />

          {/* <Route
            exact
            path={`${process.env.PUBLIC_URL}/home-one`}
            component={HomeOne}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/home-two`}
            component={HomeTwo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/home-three`}
            component={HomeThree}
          /> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about-us`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/services`}
            component={Services}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/requestquote`}
            component={Requestquote}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pcbdesign`}
            component={Pcbdesign}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pcbfabrication`}
            component={Pcbfabrication}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pcbassembly`}
            component={Pcbassembly}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/reverseservice`}
            component={Reverseservice}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/componentsourcing`}
            component={Componentsourcing}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/turnkeyproduct`}
            component={Turnkeyproduct}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/inductordesign`}
            component={Inductordesign}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/enclosuredesign`}
            component={Enclosuredesign}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pcbmanufacturing`}
            component={Pcbmanufacturing}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/service-details-left-sidebar`}
            component={ServiceDetailsLeftSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/service-details-right-sidebar`}
            component={ServiceDetailsRightSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projects`}
            component={Projects}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/project-details`}
            component={ProjectDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-left-sidebar`}
            component={BlogLeftSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-right-sidebar`}
            component={BlogRightSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details-left-sidebar`}
            component={BlogDetailsLeftSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details-right-sidebar`}
            component={BlogDetailsRightSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/faq`}
            component={Faq}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact-us`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/thankyou`}
            component={PageNotFound}
          />
          <Route component={NoMAtch} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.register();
